export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const AboutUs = () => import('../../components/pages/AboutUs.vue' /* webpackChunkName: "components/about-us" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsPage = () => import('../../components/pages/AllBrandsPage.vue' /* webpackChunkName: "components/all-brands-page" */).then(c => wrapFunctional(c.default || c))
export const AllFurniturePage = () => import('../../components/pages/AllFurniturePage.vue' /* webpackChunkName: "components/all-furniture-page" */).then(c => wrapFunctional(c.default || c))
export const BrandPage = () => import('../../components/pages/BrandPage.vue' /* webpackChunkName: "components/brand-page" */).then(c => wrapFunctional(c.default || c))
export const CartPage = () => import('../../components/pages/CartPage.vue' /* webpackChunkName: "components/cart-page" */).then(c => wrapFunctional(c.default || c))
export const CategoryPage = () => import('../../components/pages/CategoryPage.vue' /* webpackChunkName: "components/category-page" */).then(c => wrapFunctional(c.default || c))
export const ComparePage = () => import('../../components/pages/ComparePage.vue' /* webpackChunkName: "components/compare-page" */).then(c => wrapFunctional(c.default || c))
export const CouponCodePage = () => import('../../components/pages/CouponCodePage.vue' /* webpackChunkName: "components/coupon-code-page" */).then(c => wrapFunctional(c.default || c))
export const HomePage = () => import('../../components/pages/HomePage.vue' /* webpackChunkName: "components/home-page" */).then(c => wrapFunctional(c.default || c))
export const OneColumnsLayout = () => import('../../components/pages/OneColumnsLayout.vue' /* webpackChunkName: "components/one-columns-layout" */).then(c => wrapFunctional(c.default || c))
export const OnepagePage = () => import('../../components/pages/OnepagePage.vue' /* webpackChunkName: "components/onepage-page" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccess = () => import('../../components/pages/OnepageSuccess.vue' /* webpackChunkName: "components/onepage-success" */).then(c => wrapFunctional(c.default || c))
export const ProductListing = () => import('../../components/pages/ProductListing.vue' /* webpackChunkName: "components/product-listing" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/pages/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewed = () => import('../../components/pages/RecentlyViewed.vue' /* webpackChunkName: "components/recently-viewed" */).then(c => wrapFunctional(c.default || c))
export const ReviewsPage = () => import('../../components/pages/ReviewsPage.vue' /* webpackChunkName: "components/reviews-page" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingPage = () => import('../../components/pages/SalesLandingPage.vue' /* webpackChunkName: "components/sales-landing-page" */).then(c => wrapFunctional(c.default || c))
export const SearchView = () => import('../../components/pages/SearchView.vue' /* webpackChunkName: "components/search-view" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsPage = () => import('../../components/pages/TestimonialsPage.vue' /* webpackChunkName: "components/testimonials-page" */).then(c => wrapFunctional(c.default || c))
export const TwoColumnsLayout = () => import('../../components/pages/TwoColumnsLayout.vue' /* webpackChunkName: "components/two-columns-layout" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/templates/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/templates/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/templates/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheLoading = () => import('../../components/templates/TheLoading.vue' /* webpackChunkName: "components/the-loading" */).then(c => wrapFunctional(c.default || c))
export const AccountAuthPopup = () => import('../../components/templates/account/AuthPopup.vue' /* webpackChunkName: "components/account-auth-popup" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotPassword = () => import('../../components/templates/account/ForgotPassword.vue' /* webpackChunkName: "components/account-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const AccountLogin = () => import('../../components/templates/account/Login.vue' /* webpackChunkName: "components/account-login" */).then(c => wrapFunctional(c.default || c))
export const AccountSignUp = () => import('../../components/templates/account/SignUp.vue' /* webpackChunkName: "components/account-sign-up" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureView = () => import('../../components/templates/allFurniture/View.vue' /* webpackChunkName: "components/all-furniture-view" */).then(c => wrapFunctional(c.default || c))
export const BrandBanner = () => import('../../components/templates/brand/Banner.vue' /* webpackChunkName: "components/brand-banner" */).then(c => wrapFunctional(c.default || c))
export const BrandBoutiquePopup = () => import('../../components/templates/brand/BoutiquePopup.vue' /* webpackChunkName: "components/brand-boutique-popup" */).then(c => wrapFunctional(c.default || c))
export const BrandCarousel = () => import('../../components/templates/brand/Carousel.vue' /* webpackChunkName: "components/brand-carousel" */).then(c => wrapFunctional(c.default || c))
export const BrandFooter = () => import('../../components/templates/brand/Footer.vue' /* webpackChunkName: "components/brand-footer" */).then(c => wrapFunctional(c.default || c))
export const BrandReviews = () => import('../../components/templates/brand/Reviews.vue' /* webpackChunkName: "components/brand-reviews" */).then(c => wrapFunctional(c.default || c))
export const BrandSlider = () => import('../../components/templates/brand/Slider.vue' /* webpackChunkName: "components/brand-slider" */).then(c => wrapFunctional(c.default || c))
export const BrandView = () => import('../../components/templates/brand/View.vue' /* webpackChunkName: "components/brand-view" */).then(c => wrapFunctional(c.default || c))
export const CartContent = () => import('../../components/templates/cart/Content.vue' /* webpackChunkName: "components/cart-content" */).then(c => wrapFunctional(c.default || c))
export const CartEmpty = () => import('../../components/templates/cart/Empty.vue' /* webpackChunkName: "components/cart-empty" */).then(c => wrapFunctional(c.default || c))
export const CartInformation = () => import('../../components/templates/cart/Information.vue' /* webpackChunkName: "components/cart-information" */).then(c => wrapFunctional(c.default || c))
export const CartName = () => import('../../components/templates/cart/Name.vue' /* webpackChunkName: "components/cart-name" */).then(c => wrapFunctional(c.default || c))
export const CartPromoCode = () => import('../../components/templates/cart/PromoCode.vue' /* webpackChunkName: "components/cart-promo-code" */).then(c => wrapFunctional(c.default || c))
export const CartShipping = () => import('../../components/templates/cart/Shipping.vue' /* webpackChunkName: "components/cart-shipping" */).then(c => wrapFunctional(c.default || c))
export const CartSkeleton = () => import('../../components/templates/cart/Skeleton.vue' /* webpackChunkName: "components/cart-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/templates/cart/Summary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CartWhiteGlove = () => import('../../components/templates/cart/WhiteGlove.vue' /* webpackChunkName: "components/cart-white-glove" */).then(c => wrapFunctional(c.default || c))
export const AboutUsView = () => import('../../components/templates/aboutUs/view.vue' /* webpackChunkName: "components/about-us-view" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsDesktop = () => import('../../components/templates/allBrands/Desktop.vue' /* webpackChunkName: "components/all-brands-desktop" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsMobile = () => import('../../components/templates/allBrands/Mobile.vue' /* webpackChunkName: "components/all-brands-mobile" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsView = () => import('../../components/templates/allBrands/View.vue' /* webpackChunkName: "components/all-brands-view" */).then(c => wrapFunctional(c.default || c))
export const CategoryView = () => import('../../components/templates/category/view.vue' /* webpackChunkName: "components/category-view" */).then(c => wrapFunctional(c.default || c))
export const CompareProduct = () => import('../../components/templates/compare/Product.vue' /* webpackChunkName: "components/compare-product" */).then(c => wrapFunctional(c.default || c))
export const CompareProductMini = () => import('../../components/templates/compare/ProductMini.vue' /* webpackChunkName: "components/compare-product-mini" */).then(c => wrapFunctional(c.default || c))
export const CompareRecommendedCurrentProducts = () => import('../../components/templates/compare/RecommendedCurrentProducts.vue' /* webpackChunkName: "components/compare-recommended-current-products" */).then(c => wrapFunctional(c.default || c))
export const CompareRecommendedProducts = () => import('../../components/templates/compare/RecommendedProducts.vue' /* webpackChunkName: "components/compare-recommended-products" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebar = () => import('../../components/templates/compare/Sidebar.vue' /* webpackChunkName: "components/compare-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CompareThanks = () => import('../../components/templates/compare/Thanks.vue' /* webpackChunkName: "components/compare-thanks" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeView = () => import('../../components/templates/couponCode/view.vue' /* webpackChunkName: "components/coupon-code-view" */).then(c => wrapFunctional(c.default || c))
export const FooterContacts = () => import('../../components/templates/footer/Contacts.vue' /* webpackChunkName: "components/footer-contacts" */).then(c => wrapFunctional(c.default || c))
export const FooterCopyright = () => import('../../components/templates/footer/Copyright.vue' /* webpackChunkName: "components/footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterScrollTop = () => import('../../components/templates/footer/ScrollTop.vue' /* webpackChunkName: "components/footer-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const FooterSocials = () => import('../../components/templates/footer/Socials.vue' /* webpackChunkName: "components/footer-socials" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribe = () => import('../../components/templates/footer/Subscribe.vue' /* webpackChunkName: "components/footer-subscribe" */).then(c => wrapFunctional(c.default || c))
export const HomeView = () => import('../../components/templates/home/view.vue' /* webpackChunkName: "components/home-view" */).then(c => wrapFunctional(c.default || c))
export const HeaderAdditional = () => import('../../components/templates/header/Additional.vue' /* webpackChunkName: "components/header-additional" */).then(c => wrapFunctional(c.default || c))
export const HeaderMain = () => import('../../components/templates/header/Main.vue' /* webpackChunkName: "components/header-main" */).then(c => wrapFunctional(c.default || c))
export const HeaderPromotion = () => import('../../components/templates/header/Promotion.vue' /* webpackChunkName: "components/header-promotion" */).then(c => wrapFunctional(c.default || c))
export const ListingCount = () => import('../../components/templates/listing/Count.vue' /* webpackChunkName: "components/listing-count" */).then(c => wrapFunctional(c.default || c))
export const ListingFilters = () => import('../../components/templates/listing/Filters.vue' /* webpackChunkName: "components/listing-filters" */).then(c => wrapFunctional(c.default || c))
export const ListingFooter = () => import('../../components/templates/listing/Footer.vue' /* webpackChunkName: "components/listing-footer" */).then(c => wrapFunctional(c.default || c))
export const ListingHeaderPanel = () => import('../../components/templates/listing/HeaderPanel.vue' /* webpackChunkName: "components/listing-header-panel" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinder = () => import('../../components/templates/listing/MattressFinder.vue' /* webpackChunkName: "components/listing-mattress-finder" */).then(c => wrapFunctional(c.default || c))
export const ListingProducts = () => import('../../components/templates/listing/Products.vue' /* webpackChunkName: "components/listing-products" */).then(c => wrapFunctional(c.default || c))
export const ListingResults = () => import('../../components/templates/listing/Results.vue' /* webpackChunkName: "components/listing-results" */).then(c => wrapFunctional(c.default || c))
export const ListingTitle = () => import('../../components/templates/listing/Title.vue' /* webpackChunkName: "components/listing-title" */).then(c => wrapFunctional(c.default || c))
export const ListingToggleFilterSkeleton = () => import('../../components/templates/listing/ToggleFilterSkeleton.vue' /* webpackChunkName: "components/listing-toggle-filter-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ListingToggleFiltersBtn = () => import('../../components/templates/listing/ToggleFiltersBtn.vue' /* webpackChunkName: "components/listing-toggle-filters-btn" */).then(c => wrapFunctional(c.default || c))
export const ListingView = () => import('../../components/templates/listing/View.vue' /* webpackChunkName: "components/listing-view" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/templates/product/Info.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductView = () => import('../../components/templates/product/View.vue' /* webpackChunkName: "components/product-view" */).then(c => wrapFunctional(c.default || c))
export const OnepageCart = () => import('../../components/templates/onepage/Cart.vue' /* webpackChunkName: "components/onepage-cart" */).then(c => wrapFunctional(c.default || c))
export const OnepageContent = () => import('../../components/templates/onepage/Content.vue' /* webpackChunkName: "components/onepage-content" */).then(c => wrapFunctional(c.default || c))
export const OnepageControls = () => import('../../components/templates/onepage/Controls.vue' /* webpackChunkName: "components/onepage-controls" */).then(c => wrapFunctional(c.default || c))
export const OnepageErrorPopup = () => import('../../components/templates/onepage/ErrorPopup.vue' /* webpackChunkName: "components/onepage-error-popup" */).then(c => wrapFunctional(c.default || c))
export const OnepageHeader = () => import('../../components/templates/onepage/Header.vue' /* webpackChunkName: "components/onepage-header" */).then(c => wrapFunctional(c.default || c))
export const OnepagePlaceOrder = () => import('../../components/templates/onepage/PlaceOrder.vue' /* webpackChunkName: "components/onepage-place-order" */).then(c => wrapFunctional(c.default || c))
export const OnepagePromoCode = () => import('../../components/templates/onepage/PromoCode.vue' /* webpackChunkName: "components/onepage-promo-code" */).then(c => wrapFunctional(c.default || c))
export const OnepageSkeleton = () => import('../../components/templates/onepage/Skeleton.vue' /* webpackChunkName: "components/onepage-skeleton" */).then(c => wrapFunctional(c.default || c))
export const OnepageSteps = () => import('../../components/templates/onepage/Steps.vue' /* webpackChunkName: "components/onepage-steps" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummary = () => import('../../components/templates/onepage/Summary.vue' /* webpackChunkName: "components/onepage-summary" */).then(c => wrapFunctional(c.default || c))
export const OnsiteAboutOnsite = () => import('../../components/templates/onsite/AboutOnsite.vue' /* webpackChunkName: "components/onsite-about-onsite" */).then(c => wrapFunctional(c.default || c))
export const OnsiteAdvantagesTabs = () => import('../../components/templates/onsite/AdvantagesTabs.vue' /* webpackChunkName: "components/onsite-advantages-tabs" */).then(c => wrapFunctional(c.default || c))
export const OnsiteCoverageAvailability = () => import('../../components/templates/onsite/CoverageAvailability.vue' /* webpackChunkName: "components/onsite-coverage-availability" */).then(c => wrapFunctional(c.default || c))
export const OnsiteCoverageBlock = () => import('../../components/templates/onsite/CoverageBlock.vue' /* webpackChunkName: "components/onsite-coverage-block" */).then(c => wrapFunctional(c.default || c))
export const OnsiteCoverageHighlights = () => import('../../components/templates/onsite/CoverageHighlights.vue' /* webpackChunkName: "components/onsite-coverage-highlights" */).then(c => wrapFunctional(c.default || c))
export const OnsiteCoverageHighlightsBlock = () => import('../../components/templates/onsite/CoverageHighlightsBlock.vue' /* webpackChunkName: "components/onsite-coverage-highlights-block" */).then(c => wrapFunctional(c.default || c))
export const OnsiteProtectFurniture = () => import('../../components/templates/onsite/ProtectFurniture.vue' /* webpackChunkName: "components/onsite-protect-furniture" */).then(c => wrapFunctional(c.default || c))
export const OnsiteProtectionPlanPanel = () => import('../../components/templates/onsite/ProtectionPlanPanel.vue' /* webpackChunkName: "components/onsite-protection-plan-panel" */).then(c => wrapFunctional(c.default || c))
export const OnsiteTotalCare = () => import('../../components/templates/onsite/TotalCare.vue' /* webpackChunkName: "components/onsite-total-care" */).then(c => wrapFunctional(c.default || c))
export const OnsiteUltimateTitle = () => import('../../components/templates/onsite/UltimateTitle.vue' /* webpackChunkName: "components/onsite-ultimate-title" */).then(c => wrapFunctional(c.default || c))
export const OnsiteWarrantyList = () => import('../../components/templates/onsite/WarrantyList.vue' /* webpackChunkName: "components/onsite-warranty-list" */).then(c => wrapFunctional(c.default || c))
export const PromoPageBannerTimer = () => import('../../components/templates/promo/PageBannerTimer.vue' /* webpackChunkName: "components/promo-page-banner-timer" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewedEmpty = () => import('../../components/templates/recentlyViewed/Empty.vue' /* webpackChunkName: "components/recently-viewed-empty" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewedResults = () => import('../../components/templates/recentlyViewed/Results.vue' /* webpackChunkName: "components/recently-viewed-results" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewedView = () => import('../../components/templates/recentlyViewed/View.vue' /* webpackChunkName: "components/recently-viewed-view" */).then(c => wrapFunctional(c.default || c))
export const ReviewsView = () => import('../../components/templates/reviews/view.vue' /* webpackChunkName: "components/reviews-view" */).then(c => wrapFunctional(c.default || c))
export const ProtectionPlanPopup = () => import('../../components/templates/protectionPlan/Popup.vue' /* webpackChunkName: "components/protection-plan-popup" */).then(c => wrapFunctional(c.default || c))
export const ProtectionPlanPopupAdditionalContent = () => import('../../components/templates/protectionPlan/PopupAdditionalContent.vue' /* webpackChunkName: "components/protection-plan-popup-additional-content" */).then(c => wrapFunctional(c.default || c))
export const ProtectionPlanPopupMainContent = () => import('../../components/templates/protectionPlan/PopupMainContent.vue' /* webpackChunkName: "components/protection-plan-popup-main-content" */).then(c => wrapFunctional(c.default || c))
export const ProtectionPlanPopupPlans = () => import('../../components/templates/protectionPlan/PopupPlans.vue' /* webpackChunkName: "components/protection-plan-popup-plans" */).then(c => wrapFunctional(c.default || c))
export const ProtectionPlanVariants = () => import('../../components/templates/protectionPlan/Variants.vue' /* webpackChunkName: "components/protection-plan-variants" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingBanner = () => import('../../components/templates/salesLanding/Banner.vue' /* webpackChunkName: "components/sales-landing-banner" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingBestDeals = () => import('../../components/templates/salesLanding/BestDeals.vue' /* webpackChunkName: "components/sales-landing-best-deals" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingBrandSale = () => import('../../components/templates/salesLanding/BrandSale.vue' /* webpackChunkName: "components/sales-landing-brand-sale" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingCategorySale = () => import('../../components/templates/salesLanding/CategorySale.vue' /* webpackChunkName: "components/sales-landing-category-sale" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingFAQ = () => import('../../components/templates/salesLanding/FAQ.vue' /* webpackChunkName: "components/sales-landing-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingInfo = () => import('../../components/templates/salesLanding/Info.vue' /* webpackChunkName: "components/sales-landing-info" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingShopByRoom = () => import('../../components/templates/salesLanding/ShopByRoom.vue' /* webpackChunkName: "components/sales-landing-shop-by-room" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingView = () => import('../../components/templates/salesLanding/View.vue' /* webpackChunkName: "components/sales-landing-view" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsView = () => import('../../components/templates/testimonials/view.vue' /* webpackChunkName: "components/testimonials-view" */).then(c => wrapFunctional(c.default || c))
export const SearchContent = () => import('../../components/templates/search/Content.vue' /* webpackChunkName: "components/search-content" */).then(c => wrapFunctional(c.default || c))
export const SearchEmpty = () => import('../../components/templates/search/Empty.vue' /* webpackChunkName: "components/search-empty" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginFooter = () => import('../../components/templates/account/Login/Footer.vue' /* webpackChunkName: "components/account-login-footer" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginForgotPasswordForm = () => import('../../components/templates/account/Login/ForgotPasswordForm.vue' /* webpackChunkName: "components/account-login-forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginForm = () => import('../../components/templates/account/Login/Form.vue' /* webpackChunkName: "components/account-login-form" */).then(c => wrapFunctional(c.default || c))
export const AccountSignUpForm = () => import('../../components/templates/account/SignUp/Form.vue' /* webpackChunkName: "components/account-sign-up-form" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureSkeletonCategories = () => import('../../components/templates/allFurniture/skeleton/Categories.vue' /* webpackChunkName: "components/all-furniture-skeleton-categories" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureSkeletonCategoriesWithoutBanner = () => import('../../components/templates/allFurniture/skeleton/CategoriesWithoutBanner.vue' /* webpackChunkName: "components/all-furniture-skeleton-categories-without-banner" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewDynamicBanner = () => import('../../components/templates/allFurniture/view/DynamicBanner.vue' /* webpackChunkName: "components/all-furniture-view-dynamic-banner" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewMiddle = () => import('../../components/templates/allFurniture/view/Middle.vue' /* webpackChunkName: "components/all-furniture-view-middle" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewTop = () => import('../../components/templates/allFurniture/view/Top.vue' /* webpackChunkName: "components/all-furniture-view-top" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewTrendingFurniture = () => import('../../components/templates/allFurniture/view/TrendingFurniture.vue' /* webpackChunkName: "components/all-furniture-view-trending-furniture" */).then(c => wrapFunctional(c.default || c))
export const BrandBannerBgMobile = () => import('../../components/templates/brand/banner/BgMobile.vue' /* webpackChunkName: "components/brand-banner-bg-mobile" */).then(c => wrapFunctional(c.default || c))
export const BrandBannerContent = () => import('../../components/templates/brand/banner/Content.vue' /* webpackChunkName: "components/brand-banner-content" */).then(c => wrapFunctional(c.default || c))
export const BrandBannerDesc = () => import('../../components/templates/brand/banner/Desc.vue' /* webpackChunkName: "components/brand-banner-desc" */).then(c => wrapFunctional(c.default || c))
export const BrandBannerImage = () => import('../../components/templates/brand/banner/Image.vue' /* webpackChunkName: "components/brand-banner-image" */).then(c => wrapFunctional(c.default || c))
export const BrandBannerReviews = () => import('../../components/templates/brand/banner/Reviews.vue' /* webpackChunkName: "components/brand-banner-reviews" */).then(c => wrapFunctional(c.default || c))
export const BrandFooterSeoText = () => import('../../components/templates/brand/footer/SeoText.vue' /* webpackChunkName: "components/brand-footer-seo-text" */).then(c => wrapFunctional(c.default || c))
export const BrandReviewsContent = () => import('../../components/templates/brand/reviews/Content.vue' /* webpackChunkName: "components/brand-reviews-content" */).then(c => wrapFunctional(c.default || c))
export const BrandReviewsLoading = () => import('../../components/templates/brand/reviews/Loading.vue' /* webpackChunkName: "components/brand-reviews-loading" */).then(c => wrapFunctional(c.default || c))
export const BrandReviewsTop = () => import('../../components/templates/brand/reviews/Top.vue' /* webpackChunkName: "components/brand-reviews-top" */).then(c => wrapFunctional(c.default || c))
export const AboutUsSkeletonBannerImage = () => import('../../components/templates/aboutUs/skeleton/bannerImage.vue' /* webpackChunkName: "components/about-us-skeleton-banner-image" */).then(c => wrapFunctional(c.default || c))
export const AboutUsSkeletonJobDescription = () => import('../../components/templates/aboutUs/skeleton/jobDescription.vue' /* webpackChunkName: "components/about-us-skeleton-job-description" */).then(c => wrapFunctional(c.default || c))
export const AboutUsSkeletonOpenPositions = () => import('../../components/templates/aboutUs/skeleton/openPositions.vue' /* webpackChunkName: "components/about-us-skeleton-open-positions" */).then(c => wrapFunctional(c.default || c))
export const AboutUsSkeletonWelcomeForm = () => import('../../components/templates/aboutUs/skeleton/welcomeForm.vue' /* webpackChunkName: "components/about-us-skeleton-welcome-form" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewFooter = () => import('../../components/templates/aboutUs/view/Footer.vue' /* webpackChunkName: "components/about-us-view-footer" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewHeader = () => import('../../components/templates/aboutUs/view/Header.vue' /* webpackChunkName: "components/about-us-view-header" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewJobDescription = () => import('../../components/templates/aboutUs/view/JobDescription.vue' /* webpackChunkName: "components/about-us-view-job-description" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewLeftSidebar = () => import('../../components/templates/aboutUs/view/LeftSidebar.vue' /* webpackChunkName: "components/about-us-view-left-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewReviewContainer = () => import('../../components/templates/aboutUs/view/ReviewContainer.vue' /* webpackChunkName: "components/about-us-view-review-container" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewWelcomeOnestopBedroomForm = () => import('../../components/templates/aboutUs/view/WelcomeOnestopBedroomForm.vue' /* webpackChunkName: "components/about-us-view-welcome-onestop-bedroom-form" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsSkeletonDesktop = () => import('../../components/templates/allBrands/skeleton/Desktop.vue' /* webpackChunkName: "components/all-brands-skeleton-desktop" */).then(c => wrapFunctional(c.default || c))
export const AllBrandsSkeletonMobile = () => import('../../components/templates/allBrands/skeleton/Mobile.vue' /* webpackChunkName: "components/all-brands-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorative = () => import('../../components/templates/category/view/Decorative.vue' /* webpackChunkName: "components/category-view-decorative" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewPopularFurniture = () => import('../../components/templates/category/view/PopularFurniture.vue' /* webpackChunkName: "components/category-view-popular-furniture" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonDescription = () => import('../../components/templates/category/skeleton/Description.vue' /* webpackChunkName: "components/category-skeleton-description" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonLeftSidebar = () => import('../../components/templates/category/skeleton/LeftSidebar.vue' /* webpackChunkName: "components/category-skeleton-left-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonMainCategory = () => import('../../components/templates/category/skeleton/MainCategory.vue' /* webpackChunkName: "components/category-skeleton-main-category" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonPopularFurniture = () => import('../../components/templates/category/skeleton/PopularFurniture.vue' /* webpackChunkName: "components/category-skeleton-popular-furniture" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonRecommendedArticle = () => import('../../components/templates/category/skeleton/RecommendedArticle.vue' /* webpackChunkName: "components/category-skeleton-recommended-article" */).then(c => wrapFunctional(c.default || c))
export const CategorySkeletonRelatedSearch = () => import('../../components/templates/category/skeleton/RelatedSearch.vue' /* webpackChunkName: "components/category-skeleton-related-search" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarAction = () => import('../../components/templates/compare/sidebar/Action.vue' /* webpackChunkName: "components/compare-sidebar-action" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarDefault = () => import('../../components/templates/compare/sidebar/Default.vue' /* webpackChunkName: "components/compare-sidebar-default" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarLoading = () => import('../../components/templates/compare/sidebar/Loading.vue' /* webpackChunkName: "components/compare-sidebar-loading" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarProduct = () => import('../../components/templates/compare/sidebar/Product.vue' /* webpackChunkName: "components/compare-sidebar-product" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarRequest = () => import('../../components/templates/compare/sidebar/Request.vue' /* webpackChunkName: "components/compare-sidebar-request" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeSkeletonBanner = () => import('../../components/templates/couponCode/skeleton/Banner.vue' /* webpackChunkName: "components/coupon-code-skeleton-banner" */).then(c => wrapFunctional(c.default || c))
export const CouponBoxList = () => import('../../components/templates/couponCode/skeleton/CouponBoxList.vue' /* webpackChunkName: "components/coupon-box-list" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeSkeletonIconList = () => import('../../components/templates/couponCode/skeleton/IconList.vue' /* webpackChunkName: "components/coupon-code-skeleton-icon-list" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeViewBottom = () => import('../../components/templates/couponCode/view/Bottom.vue' /* webpackChunkName: "components/coupon-code-view-bottom" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeViewDynamicBanner = () => import('../../components/templates/couponCode/view/DynamicBanner.vue' /* webpackChunkName: "components/coupon-code-view-dynamic-banner" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeViewDynamicContent = () => import('../../components/templates/couponCode/view/DynamicContent.vue' /* webpackChunkName: "components/coupon-code-view-dynamic-content" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationLogo = () => import('../../components/templates/footer/navigation/Logo.vue' /* webpackChunkName: "components/footer-navigation-logo" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationMenu = () => import('../../components/templates/footer/navigation/Menu.vue' /* webpackChunkName: "components/footer-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterNavigationWidget = () => import('../../components/templates/footer/navigation/Widget.vue' /* webpackChunkName: "components/footer-navigation-widget" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribeForm = () => import('../../components/templates/footer/subscribe/Form.vue' /* webpackChunkName: "components/footer-subscribe-form" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribePopup = () => import('../../components/templates/footer/subscribe/Popup.vue' /* webpackChunkName: "components/footer-subscribe-popup" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribePopupWithTimeout = () => import('../../components/templates/footer/subscribe/PopupWithTimeout.vue' /* webpackChunkName: "components/footer-subscribe-popup-with-timeout" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribeStep1 = () => import('../../components/templates/footer/subscribe/Step1.vue' /* webpackChunkName: "components/footer-subscribe-step1" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribeStep2 = () => import('../../components/templates/footer/subscribe/Step2.vue' /* webpackChunkName: "components/footer-subscribe-step2" */).then(c => wrapFunctional(c.default || c))
export const HomeSkeletonBanner = () => import('../../components/templates/home/skeleton/Banner.vue' /* webpackChunkName: "components/home-skeleton-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeSkeletonBuyAsSet = () => import('../../components/templates/home/skeleton/BuyAsSet.vue' /* webpackChunkName: "components/home-skeleton-buy-as-set" */).then(c => wrapFunctional(c.default || c))
export const HomeSkeletonCategory = () => import('../../components/templates/home/skeleton/Category.vue' /* webpackChunkName: "components/home-skeleton-category" */).then(c => wrapFunctional(c.default || c))
export const HomeSkeletonCloudSofa = () => import('../../components/templates/home/skeleton/CloudSofa.vue' /* webpackChunkName: "components/home-skeleton-cloud-sofa" */).then(c => wrapFunctional(c.default || c))
export const HomeSkeletonPopularCategory = () => import('../../components/templates/home/skeleton/PopularCategory.vue' /* webpackChunkName: "components/home-skeleton-popular-category" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBanner = () => import('../../components/templates/home/view/Banner.vue' /* webpackChunkName: "components/home-view-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBuyAsSet = () => import('../../components/templates/home/view/BuyAsSet.vue' /* webpackChunkName: "components/home-view-buy-as-set" */).then(c => wrapFunctional(c.default || c))
export const HomeViewCategory = () => import('../../components/templates/home/view/Category.vue' /* webpackChunkName: "components/home-view-category" */).then(c => wrapFunctional(c.default || c))
export const HomeViewCategoryItems = () => import('../../components/templates/home/view/CategoryItems.vue' /* webpackChunkName: "components/home-view-category-items" */).then(c => wrapFunctional(c.default || c))
export const HomeViewClearanceBanner = () => import('../../components/templates/home/view/ClearanceBanner.vue' /* webpackChunkName: "components/home-view-clearance-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeViewCloudSofa = () => import('../../components/templates/home/view/CloudSofa.vue' /* webpackChunkName: "components/home-view-cloud-sofa" */).then(c => wrapFunctional(c.default || c))
export const HomeViewFeatureBrands = () => import('../../components/templates/home/view/FeatureBrands.vue' /* webpackChunkName: "components/home-view-feature-brands" */).then(c => wrapFunctional(c.default || c))
export const HomeViewFooterBefore = () => import('../../components/templates/home/view/FooterBefore.vue' /* webpackChunkName: "components/home-view-footer-before" */).then(c => wrapFunctional(c.default || c))
export const HomeViewPopularCategory = () => import('../../components/templates/home/view/PopularCategory.vue' /* webpackChunkName: "components/home-view-popular-category" */).then(c => wrapFunctional(c.default || c))
export const HomeViewStaticBannerBrand = () => import('../../components/templates/home/view/StaticBannerBrand.vue' /* webpackChunkName: "components/home-view-static-banner-brand" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccountButton = () => import('../../components/templates/header/account/Button.vue' /* webpackChunkName: "components/header-account-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccountContent = () => import('../../components/templates/header/account/Content.vue' /* webpackChunkName: "components/header-account-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccountMenu = () => import('../../components/templates/header/account/Menu.vue' /* webpackChunkName: "components/header-account-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccountWrapper = () => import('../../components/templates/header/account/Wrapper.vue' /* webpackChunkName: "components/header-account-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HeaderAdditionalLink = () => import('../../components/templates/header/additional/Link.vue' /* webpackChunkName: "components/header-additional-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderAdditionalPhone = () => import('../../components/templates/header/additional/Phone.vue' /* webpackChunkName: "components/header-additional-phone" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartContent = () => import('../../components/templates/header/cart/Content.vue' /* webpackChunkName: "components/header-cart-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartCoupon = () => import('../../components/templates/header/cart/Coupon.vue' /* webpackChunkName: "components/header-cart-coupon" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartCouponTimer = () => import('../../components/templates/header/cart/CouponTimer.vue' /* webpackChunkName: "components/header-cart-coupon-timer" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartEmpty = () => import('../../components/templates/header/cart/Empty.vue' /* webpackChunkName: "components/header-cart-empty" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItem = () => import('../../components/templates/header/cart/Item.vue' /* webpackChunkName: "components/header-cart-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemDummyProducts = () => import('../../components/templates/header/cart/ItemDummyProducts.vue' /* webpackChunkName: "components/header-cart-item-dummy-products" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemGuardian = () => import('../../components/templates/header/cart/ItemGuardian.vue' /* webpackChunkName: "components/header-cart-item-guardian" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemPrice = () => import('../../components/templates/header/cart/ItemPrice.vue' /* webpackChunkName: "components/header-cart-item-price" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemProtection = () => import('../../components/templates/header/cart/ItemProtection.vue' /* webpackChunkName: "components/header-cart-item-protection" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemQuantity = () => import('../../components/templates/header/cart/ItemQuantity.vue' /* webpackChunkName: "components/header-cart-item-quantity" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemSet = () => import('../../components/templates/header/cart/ItemSet.vue' /* webpackChunkName: "components/header-cart-item-set" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemSetChild = () => import('../../components/templates/header/cart/ItemSetChild.vue' /* webpackChunkName: "components/header-cart-item-set-child" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemSkeleton = () => import('../../components/templates/header/cart/ItemSkeleton.vue' /* webpackChunkName: "components/header-cart-item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartLoading = () => import('../../components/templates/header/cart/Loading.vue' /* webpackChunkName: "components/header-cart-loading" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartWrapper = () => import('../../components/templates/header/cart/Wrapper.vue' /* webpackChunkName: "components/header-cart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HeaderMainLogo = () => import('../../components/templates/header/main/Logo.vue' /* webpackChunkName: "components/header-main-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuDesktop = () => import('../../components/templates/header/menu/Desktop.vue' /* webpackChunkName: "components/header-menu-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuMobile = () => import('../../components/templates/header/menu/Mobile.vue' /* webpackChunkName: "components/header-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuWrapper = () => import('../../components/templates/header/menu/Wrapper.vue' /* webpackChunkName: "components/header-menu-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchWrapper = () => import('../../components/templates/header/search/Wrapper.vue' /* webpackChunkName: "components/header-search-wrapper" */).then(c => wrapFunctional(c.default || c))
export const HeaderWishListLink = () => import('../../components/templates/header/wishList/Link.vue' /* webpackChunkName: "components/header-wish-list-link" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersContent = () => import('../../components/templates/listing/filters/Content.vue' /* webpackChunkName: "components/listing-filters-content" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelBrands = () => import('../../components/templates/listing/filters/PanelBrands.vue' /* webpackChunkName: "components/listing-filters-panel-brands" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCatCheckboxList = () => import('../../components/templates/listing/filters/PanelCatCheckboxList.vue' /* webpackChunkName: "components/listing-filters-panel-cat-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCategory = () => import('../../components/templates/listing/filters/PanelCategory.vue' /* webpackChunkName: "components/listing-filters-panel-category" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCheckboxList = () => import('../../components/templates/listing/filters/PanelCheckboxList.vue' /* webpackChunkName: "components/listing-filters-panel-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCollection = () => import('../../components/templates/listing/filters/PanelCollection.vue' /* webpackChunkName: "components/listing-filters-panel-collection" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCommon = () => import('../../components/templates/listing/filters/PanelCommon.vue' /* webpackChunkName: "components/listing-filters-panel-common" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelCommonNew = () => import('../../components/templates/listing/filters/PanelCommonNew.vue' /* webpackChunkName: "components/listing-filters-panel-common-new" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelLayoutNavigate = () => import('../../components/templates/listing/filters/PanelLayoutNavigate.vue' /* webpackChunkName: "components/listing-filters-panel-layout-navigate" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersPanelPrice = () => import('../../components/templates/listing/filters/PanelPrice.vue' /* webpackChunkName: "components/listing-filters-panel-price" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersSearchInput = () => import('../../components/templates/listing/filters/SearchInput.vue' /* webpackChunkName: "components/listing-filters-search-input" */).then(c => wrapFunctional(c.default || c))
export const ListingFiltersSkeleton = () => import('../../components/templates/listing/filters/Skeleton.vue' /* webpackChunkName: "components/listing-filters-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterArticle = () => import('../../components/templates/listing/footer/Article.vue' /* webpackChunkName: "components/listing-footer-article" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterArticles = () => import('../../components/templates/listing/footer/Articles.vue' /* webpackChunkName: "components/listing-footer-articles" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterFilters = () => import('../../components/templates/listing/footer/Filters.vue' /* webpackChunkName: "components/listing-footer-filters" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterProducts = () => import('../../components/templates/listing/footer/Products.vue' /* webpackChunkName: "components/listing-footer-products" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterSeoText = () => import('../../components/templates/listing/footer/SeoText.vue' /* webpackChunkName: "components/listing-footer-seo-text" */).then(c => wrapFunctional(c.default || c))
export const ListingFooterSliderProducts = () => import('../../components/templates/listing/footer/SliderProducts.vue' /* webpackChunkName: "components/listing-footer-slider-products" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderForm = () => import('../../components/templates/listing/mattressFinder/Form.vue' /* webpackChunkName: "components/listing-mattress-finder-form" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderInactive = () => import('../../components/templates/listing/mattressFinder/Inactive.vue' /* webpackChunkName: "components/listing-mattress-finder-inactive" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderProgress = () => import('../../components/templates/listing/mattressFinder/Progress.vue' /* webpackChunkName: "components/listing-mattress-finder-progress" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderReady = () => import('../../components/templates/listing/mattressFinder/Ready.vue' /* webpackChunkName: "components/listing-mattress-finder-ready" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderResult = () => import('../../components/templates/listing/mattressFinder/Result.vue' /* webpackChunkName: "components/listing-mattress-finder-result" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderStep = () => import('../../components/templates/listing/mattressFinder/Step.vue' /* webpackChunkName: "components/listing-mattress-finder-step" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderTitle = () => import('../../components/templates/listing/mattressFinder/Title.vue' /* webpackChunkName: "components/listing-mattress-finder-title" */).then(c => wrapFunctional(c.default || c))
export const ListingMattressFinderWait = () => import('../../components/templates/listing/mattressFinder/Wait.vue' /* webpackChunkName: "components/listing-mattress-finder-wait" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsChip = () => import('../../components/templates/listing/products/Chip.vue' /* webpackChunkName: "components/listing-products-chip" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsChips = () => import('../../components/templates/listing/products/Chips.vue' /* webpackChunkName: "components/listing-products-chips" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsList = () => import('../../components/templates/listing/products/List.vue' /* webpackChunkName: "components/listing-products-list" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItem = () => import('../../components/templates/listing/products/ListItem.vue' /* webpackChunkName: "components/listing-products-list-item" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListSkeleton = () => import('../../components/templates/listing/products/ListSkeleton.vue' /* webpackChunkName: "components/listing-products-list-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsNavigation = () => import('../../components/templates/listing/products/Navigation.vue' /* webpackChunkName: "components/listing-products-navigation" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsSort = () => import('../../components/templates/listing/products/Sort.vue' /* webpackChunkName: "components/listing-products-sort" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoAccessories = () => import('../../components/templates/product/info/Accessories.vue' /* webpackChunkName: "components/product-info-accessories" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoAccessory = () => import('../../components/templates/product/info/Accessory.vue' /* webpackChunkName: "components/product-info-accessory" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoConfigurableItem = () => import('../../components/templates/product/info/ConfigurableItem.vue' /* webpackChunkName: "components/product-info-configurable-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetails = () => import('../../components/templates/product/info/Details.vue' /* webpackChunkName: "components/product-info-details" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoMoreFromCollection = () => import('../../components/templates/product/info/MoreFromCollection.vue' /* webpackChunkName: "components/product-info-more-from-collection" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattress = () => import('../../components/templates/product/info/RecommendedMattress.vue' /* webpackChunkName: "components/product-info-recommended-mattress" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRelatedSearch = () => import('../../components/templates/product/info/RelatedSearch.vue' /* webpackChunkName: "components/product-info-related-search" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviews = () => import('../../components/templates/product/info/Reviews.vue' /* webpackChunkName: "components/product-info-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoShippingAndReturn = () => import('../../components/templates/product/info/ShippingAndReturn.vue' /* webpackChunkName: "components/product-info-shipping-and-return" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoSyteCarousel = () => import('../../components/templates/product/info/SyteCarousel.vue' /* webpackChunkName: "components/product-info-syte-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductPopupDeliveryProcessExplained = () => import('../../components/templates/product/popup/DeliveryProcessExplained.vue' /* webpackChunkName: "components/product-popup-delivery-process-explained" */).then(c => wrapFunctional(c.default || c))
export const ProductPopupExpressDeliveryProcess = () => import('../../components/templates/product/popup/ExpressDeliveryProcess.vue' /* webpackChunkName: "components/product-popup-express-delivery-process" */).then(c => wrapFunctional(c.default || c))
export const ProductPopupPriceMatchGuarantee = () => import('../../components/templates/product/popup/PriceMatchGuarantee.vue' /* webpackChunkName: "components/product-popup-price-match-guarantee" */).then(c => wrapFunctional(c.default || c))
export const ProductPopupSetSelectionPopup = () => import('../../components/templates/product/popup/SetSelectionPopup.vue' /* webpackChunkName: "components/product-popup-set-selection-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductPopupShopMoreSaveMore = () => import('../../components/templates/product/popup/ShopMoreSaveMore.vue' /* webpackChunkName: "components/product-popup-shop-more-save-more" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonAccessories = () => import('../../components/templates/product/skeleton/Accessories.vue' /* webpackChunkName: "components/product-skeleton-accessories" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonGeneral = () => import('../../components/templates/product/skeleton/General.vue' /* webpackChunkName: "components/product-skeleton-general" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonGrouping = () => import('../../components/templates/product/skeleton/Grouping.vue' /* webpackChunkName: "components/product-skeleton-grouping" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonRelatedItem = () => import('../../components/templates/product/skeleton/RelatedItem.vue' /* webpackChunkName: "components/product-skeleton-related-item" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonSyteCarousel = () => import('../../components/templates/product/skeleton/SyteCarousel.vue' /* webpackChunkName: "components/product-skeleton-syte-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonTitle = () => import('../../components/templates/product/skeleton/Title.vue' /* webpackChunkName: "components/product-skeleton-title" */).then(c => wrapFunctional(c.default || c))
export const OnepageProgressBarDesktop = () => import('../../components/templates/onepage/progressBar/Desktop.vue' /* webpackChunkName: "components/onepage-progress-bar-desktop" */).then(c => wrapFunctional(c.default || c))
export const OnepageProgressBarMobile = () => import('../../components/templates/onepage/progressBar/Mobile.vue' /* webpackChunkName: "components/onepage-progress-bar-mobile" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAccountForm = () => import('../../components/templates/onepage/steps/AccountForm.vue' /* webpackChunkName: "components/onepage-steps-account-form" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAddress = () => import('../../components/templates/onepage/steps/Address.vue' /* webpackChunkName: "components/onepage-steps-address" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDelivery = () => import('../../components/templates/onepage/steps/Delivery.vue' /* webpackChunkName: "components/onepage-steps-delivery" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPayment = () => import('../../components/templates/onepage/steps/Payment.vue' /* webpackChunkName: "components/onepage-steps-payment" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsStep = () => import('../../components/templates/onepage/steps/Step.vue' /* webpackChunkName: "components/onepage-steps-step" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessDetails = () => import('../../components/templates/onepage/success/Details.vue' /* webpackChunkName: "components/onepage-success-details" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessDetailsPaymentLogo = () => import('../../components/templates/onepage/success/DetailsPaymentLogo.vue' /* webpackChunkName: "components/onepage-success-details-payment-logo" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessInfo = () => import('../../components/templates/onepage/success/Info.vue' /* webpackChunkName: "components/onepage-success-info" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessInstagram = () => import('../../components/templates/onepage/success/Instagram.vue' /* webpackChunkName: "components/onepage-success-instagram" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessPopup = () => import('../../components/templates/onepage/success/Popup.vue' /* webpackChunkName: "components/onepage-success-popup" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessShipments = () => import('../../components/templates/onepage/success/Shipments.vue' /* webpackChunkName: "components/onepage-success-shipments" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessSummary = () => import('../../components/templates/onepage/success/Summary.vue' /* webpackChunkName: "components/onepage-success-summary" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryItemInfo = () => import('../../components/templates/onepage/summary/ItemInfo.vue' /* webpackChunkName: "components/onepage-summary-item-info" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryItemProtection = () => import('../../components/templates/onepage/summary/ItemProtection.vue' /* webpackChunkName: "components/onepage-summary-item-protection" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryItemSet = () => import('../../components/templates/onepage/summary/ItemSet.vue' /* webpackChunkName: "components/onepage-summary-item-set" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryItemSetChild = () => import('../../components/templates/onepage/summary/ItemSetChild.vue' /* webpackChunkName: "components/onepage-summary-item-set-child" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryShipments = () => import('../../components/templates/onepage/summary/Shipments.vue' /* webpackChunkName: "components/onepage-summary-shipments" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractConditions = () => import('../../components/templates/onsite/Contract/Conditions.vue' /* webpackChunkName: "components/onsite-contract-conditions" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractContentWrapper = () => import('../../components/templates/onsite/Contract/ContentWrapper.vue' /* webpackChunkName: "components/onsite-contract-content-wrapper" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractCoverage = () => import('../../components/templates/onsite/Contract/Coverage.vue' /* webpackChunkName: "components/onsite-contract-coverage" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractData = () => import('../../components/templates/onsite/Contract/Data.vue' /* webpackChunkName: "components/onsite-contract-data" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractDefinitions = () => import('../../components/templates/onsite/Contract/Definitions.vue' /* webpackChunkName: "components/onsite-contract-definitions" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractHeader = () => import('../../components/templates/onsite/Contract/Header.vue' /* webpackChunkName: "components/onsite-contract-header" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractLimitOfLiability = () => import('../../components/templates/onsite/Contract/LimitOfLiability.vue' /* webpackChunkName: "components/onsite-contract-limit-of-liability" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractNotCovered = () => import('../../components/templates/onsite/Contract/NotCovered.vue' /* webpackChunkName: "components/onsite-contract-not-covered" */).then(c => wrapFunctional(c.default || c))
export const OnsiteContractTermsCoverage = () => import('../../components/templates/onsite/Contract/TermsCoverage.vue' /* webpackChunkName: "components/onsite-contract-terms-coverage" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewedEmptyMessage = () => import('../../components/templates/recentlyViewed/empty/EmptyMessage.vue' /* webpackChunkName: "components/recently-viewed-empty-message" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonBanner = () => import('../../components/templates/reviews/skeleton/Banner.vue' /* webpackChunkName: "components/reviews-skeleton-banner" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonFeedback = () => import('../../components/templates/reviews/skeleton/Feedback.vue' /* webpackChunkName: "components/reviews-skeleton-feedback" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonMapSection = () => import('../../components/templates/reviews/skeleton/MapSection.vue' /* webpackChunkName: "components/reviews-skeleton-map-section" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonSiteRatingsList = () => import('../../components/templates/reviews/skeleton/SiteRatingsList.vue' /* webpackChunkName: "components/reviews-skeleton-site-ratings-list" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonTabSection = () => import('../../components/templates/reviews/skeleton/TabSection.vue' /* webpackChunkName: "components/reviews-skeleton-tab-section" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewBanner = () => import('../../components/templates/reviews/view/Banner.vue' /* webpackChunkName: "components/reviews-view-banner" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewFeedback = () => import('../../components/templates/reviews/view/Feedback.vue' /* webpackChunkName: "components/reviews-view-feedback" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewLazyImage = () => import('../../components/templates/reviews/view/LazyImage.vue' /* webpackChunkName: "components/reviews-view-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewMapSection = () => import('../../components/templates/reviews/view/MapSection.vue' /* webpackChunkName: "components/reviews-view-map-section" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewSiteRatingsList = () => import('../../components/templates/reviews/view/SiteRatingsList.vue' /* webpackChunkName: "components/reviews-view-site-ratings-list" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSection = () => import('../../components/templates/reviews/view/TabSection.vue' /* webpackChunkName: "components/reviews-view-tab-section" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingSliderItem = () => import('../../components/templates/salesLanding/slider/Item.vue' /* webpackChunkName: "components/sales-landing-slider-item" */).then(c => wrapFunctional(c.default || c))
export const SalesLandingSliderWrapper = () => import('../../components/templates/salesLanding/slider/Wrapper.vue' /* webpackChunkName: "components/sales-landing-slider-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSkeletonBanner = () => import('../../components/templates/testimonials/skeleton/Banner.vue' /* webpackChunkName: "components/testimonials-skeleton-banner" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSkeletonFeedback = () => import('../../components/templates/testimonials/skeleton/Feedback.vue' /* webpackChunkName: "components/testimonials-skeleton-feedback" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSkeletonFilterOption = () => import('../../components/templates/testimonials/skeleton/FilterOption.vue' /* webpackChunkName: "components/testimonials-skeleton-filter-option" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSkeletonMapSection = () => import('../../components/templates/testimonials/skeleton/MapSection.vue' /* webpackChunkName: "components/testimonials-skeleton-map-section" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSkeletonTabSection = () => import('../../components/templates/testimonials/skeleton/TabSection.vue' /* webpackChunkName: "components/testimonials-skeleton-tab-section" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewBanner = () => import('../../components/templates/testimonials/view/Banner.vue' /* webpackChunkName: "components/testimonials-view-banner" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewFeedback = () => import('../../components/templates/testimonials/view/Feedback.vue' /* webpackChunkName: "components/testimonials-view-feedback" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewFilterOption = () => import('../../components/templates/testimonials/view/FilterOption.vue' /* webpackChunkName: "components/testimonials-view-filter-option" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewMapSection = () => import('../../components/templates/testimonials/view/MapSection.vue' /* webpackChunkName: "components/testimonials-view-map-section" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewTabSection = () => import('../../components/templates/testimonials/view/TabSection.vue' /* webpackChunkName: "components/testimonials-view-tab-section" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewWriteReview = () => import('../../components/templates/testimonials/view/WriteReview.vue' /* webpackChunkName: "components/testimonials-view-write-review" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyCategories = () => import('../../components/templates/search/empty/Categories.vue' /* webpackChunkName: "components/search-empty-categories" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyCategoriesSkeleton = () => import('../../components/templates/search/empty/CategoriesSkeleton.vue' /* webpackChunkName: "components/search-empty-categories-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyCategoriesTitle = () => import('../../components/templates/search/empty/CategoriesTitle.vue' /* webpackChunkName: "components/search-empty-categories-title" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyImageSearch = () => import('../../components/templates/search/empty/ImageSearch.vue' /* webpackChunkName: "components/search-empty-image-search" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyTitle = () => import('../../components/templates/search/empty/Title.vue' /* webpackChunkName: "components/search-empty-title" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewMiddleSubCategory = () => import('../../components/templates/allFurniture/view/middle/SubCategory.vue' /* webpackChunkName: "components/all-furniture-view-middle-sub-category" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewTrendingFurniturePrice = () => import('../../components/templates/allFurniture/view/trendingFurniture/Price.vue' /* webpackChunkName: "components/all-furniture-view-trending-furniture-price" */).then(c => wrapFunctional(c.default || c))
export const AllFurnitureViewTrendingFurnitureReviews = () => import('../../components/templates/allFurniture/view/trendingFurniture/Reviews.vue' /* webpackChunkName: "components/all-furniture-view-trending-furniture-reviews" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewFooterDesktop = () => import('../../components/templates/aboutUs/view/Footer/FooterDesktop.vue' /* webpackChunkName: "components/about-us-view-footer-desktop" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewFooterMobile = () => import('../../components/templates/aboutUs/view/Footer/FooterMobile.vue' /* webpackChunkName: "components/about-us-view-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewFooterLogo = () => import('../../components/templates/aboutUs/view/Footer/Logo.vue' /* webpackChunkName: "components/about-us-view-footer-logo" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewHeaderDesktop = () => import('../../components/templates/aboutUs/view/Header/HeaderDesktop.vue' /* webpackChunkName: "components/about-us-view-header-desktop" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewHeaderMobile = () => import('../../components/templates/aboutUs/view/Header/HeaderMobile.vue' /* webpackChunkName: "components/about-us-view-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const AboutUsMainContent = () => import('../../components/templates/aboutUs/view/MainContent/aboutUsMainContent.vue' /* webpackChunkName: "components/about-us-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentCareersMainContent = () => import('../../components/templates/aboutUs/view/MainContent/careersMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-careers-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentContactUsMainContent = () => import('../../components/templates/aboutUs/view/MainContent/contactUsMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-contact-us-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentOneStopAdvantageMainContent = () => import('../../components/templates/aboutUs/view/MainContent/oneStopAdvantageMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-one-stop-advantage-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentOneStopCaresMainContent = () => import('../../components/templates/aboutUs/view/MainContent/oneStopCaresMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-one-stop-cares-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentOurStoryMainContent = () => import('../../components/templates/aboutUs/view/MainContent/ourStoryMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-our-story-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewMainContentPartnerWithUsMainContent = () => import('../../components/templates/aboutUs/view/MainContent/partnerWithUsMainContent.vue' /* webpackChunkName: "components/about-us-view-main-content-partner-with-us-main-content" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewPopupErrorMessage = () => import('../../components/templates/aboutUs/view/Popup/ErrorMessage.vue' /* webpackChunkName: "components/about-us-view-popup-error-message" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewPopupSuccessMessage = () => import('../../components/templates/aboutUs/view/Popup/SuccessMessage.vue' /* webpackChunkName: "components/about-us-view-popup-success-message" */).then(c => wrapFunctional(c.default || c))
export const AboutUsViewCareersOpenPosition = () => import('../../components/templates/aboutUs/view/careers/OpenPosition.vue' /* webpackChunkName: "components/about-us-view-careers-open-position" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorativeLeftSidebar = () => import('../../components/templates/category/view/decorative/LeftSidebar.vue' /* webpackChunkName: "components/category-view-decorative-left-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorativeMainCategory = () => import('../../components/templates/category/view/decorative/MainCategory.vue' /* webpackChunkName: "components/category-view-decorative-main-category" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeViewBottomContent = () => import('../../components/templates/couponCode/view/bottom/Content.vue' /* webpackChunkName: "components/coupon-code-view-bottom-content" */).then(c => wrapFunctional(c.default || c))
export const CouponBox = () => import('../../components/templates/couponCode/view/bottom/CouponBox.vue' /* webpackChunkName: "components/coupon-box" */).then(c => wrapFunctional(c.default || c))
export const HomeViewPopularCategoryItem = () => import('../../components/templates/home/view/PopularCategory/Item.vue' /* webpackChunkName: "components/home-view-popular-category-item" */).then(c => wrapFunctional(c.default || c))
export const HomeViewPopupShopMoreSaveMore = () => import('../../components/templates/home/view/Popup/ShopMoreSaveMore.vue' /* webpackChunkName: "components/home-view-popup-shop-more-save-more" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerBottomBanner = () => import('../../components/templates/home/view/banner/BottomBanner.vue' /* webpackChunkName: "components/home-view-banner-bottom-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerCenterBannerDynamic = () => import('../../components/templates/home/view/banner/CenterBannerDynamic.vue' /* webpackChunkName: "components/home-view-banner-center-banner-dynamic" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerCenterBannerStatic = () => import('../../components/templates/home/view/banner/CenterBannerStatic.vue' /* webpackChunkName: "components/home-view-banner-center-banner-static" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerLeftBanner = () => import('../../components/templates/home/view/banner/LeftBanner.vue' /* webpackChunkName: "components/home-view-banner-left-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerRightBanner = () => import('../../components/templates/home/view/banner/RightBanner.vue' /* webpackChunkName: "components/home-view-banner-right-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerSection = () => import('../../components/templates/home/view/banner/Section.vue' /* webpackChunkName: "components/home-view-banner-section" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerSideBanner = () => import('../../components/templates/home/view/banner/SideBanner.vue' /* webpackChunkName: "components/home-view-banner-side-banner" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsCatalog = () => import('../../components/templates/header/menu/brands/Catalog.vue' /* webpackChunkName: "components/header-menu-brands-catalog" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsCatalogIndex = () => import('../../components/templates/header/menu/brands/CatalogIndex.vue' /* webpackChunkName: "components/header-menu-brands-catalog-index" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsCatalogLetter = () => import('../../components/templates/header/menu/brands/CatalogLetter.vue' /* webpackChunkName: "components/header-menu-brands-catalog-letter" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsDesktop = () => import('../../components/templates/header/menu/brands/Desktop.vue' /* webpackChunkName: "components/header-menu-brands-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsLuxury = () => import('../../components/templates/header/menu/brands/Luxury.vue' /* webpackChunkName: "components/header-menu-brands-luxury" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuBrandsMobile = () => import('../../components/templates/header/menu/brands/Mobile.vue' /* webpackChunkName: "components/header-menu-brands-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesChildLevel = () => import('../../components/templates/header/menu/categories/ChildLevel.vue' /* webpackChunkName: "components/header-menu-categories-child-level" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesDesktop = () => import('../../components/templates/header/menu/categories/Desktop.vue' /* webpackChunkName: "components/header-menu-categories-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesDesktopParentLevel = () => import('../../components/templates/header/menu/categories/DesktopParentLevel.vue' /* webpackChunkName: "components/header-menu-categories-desktop-parent-level" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesDesktopParentLevelItem = () => import('../../components/templates/header/menu/categories/DesktopParentLevelItem.vue' /* webpackChunkName: "components/header-menu-categories-desktop-parent-level-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesItemLink = () => import('../../components/templates/header/menu/categories/ItemLink.vue' /* webpackChunkName: "components/header-menu-categories-item-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesItemLinkHref = () => import('../../components/templates/header/menu/categories/ItemLinkHref.vue' /* webpackChunkName: "components/header-menu-categories-item-link-href" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuCategoriesSeo = () => import('../../components/templates/header/menu/categories/Seo.vue' /* webpackChunkName: "components/header-menu-categories-seo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuMobileAuth = () => import('../../components/templates/header/menu/mobile/Auth.vue' /* webpackChunkName: "components/header-menu-mobile-auth" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuMobileLink = () => import('../../components/templates/header/menu/mobile/Link.vue' /* webpackChunkName: "components/header-menu-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuMobileLinks = () => import('../../components/templates/header/menu/mobile/Links.vue' /* webpackChunkName: "components/header-menu-mobile-links" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuMobileParentLevelItem = () => import('../../components/templates/header/menu/mobile/ParentLevelItem.vue' /* webpackChunkName: "components/header-menu-mobile-parent-level-item" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemDynamics = () => import('../../components/templates/listing/products/ListItem/Dynamics.vue' /* webpackChunkName: "components/listing-products-list-item-dynamics" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemImage = () => import('../../components/templates/listing/products/ListItem/Image.vue' /* webpackChunkName: "components/listing-products-list-item-image" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemImagesSlider = () => import('../../components/templates/listing/products/ListItem/ImagesSlider.vue' /* webpackChunkName: "components/listing-products-list-item-images-slider" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemPrice = () => import('../../components/templates/listing/products/ListItem/Price.vue' /* webpackChunkName: "components/listing-products-list-item-price" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemReviews = () => import('../../components/templates/listing/products/ListItem/Reviews.vue' /* webpackChunkName: "components/listing-products-list-item-reviews" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemTable = () => import('../../components/templates/listing/products/ListItem/Table.vue' /* webpackChunkName: "components/listing-products-list-item-table" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemWishlistBtn = () => import('../../components/templates/listing/products/ListItem/WishlistBtn.vue' /* webpackChunkName: "components/listing-products-list-item-wishlist-btn" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoConfigurableItemOption = () => import('../../components/templates/product/info/configurableItem/Option.vue' /* webpackChunkName: "components/product-info-configurable-item-option" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoConfigurableItemSelection = () => import('../../components/templates/product/info/configurableItem/Selection.vue' /* webpackChunkName: "components/product-info-configurable-item-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditional = () => import('../../components/templates/product/info/details/Additional.vue' /* webpackChunkName: "components/product-info-details-additional" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneral = () => import('../../components/templates/product/info/details/General.vue' /* webpackChunkName: "components/product-info-details-general" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallery = () => import('../../components/templates/product/info/gallery/Gallery.vue' /* webpackChunkName: "components/product-info-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGalleryImgInternalZoom = () => import('../../components/templates/product/info/gallery/GalleryImgInternalZoom.vue' /* webpackChunkName: "components/product-info-gallery-img-internal-zoom" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGalleryImgZoom = () => import('../../components/templates/product/info/gallery/GalleryImgZoom.vue' /* webpackChunkName: "components/product-info-gallery-img-zoom" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGalleryShopSimilarItems = () => import('../../components/templates/product/info/gallery/GalleryShopSimilarItems.vue' /* webpackChunkName: "components/product-info-gallery-shop-similar-items" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallerySliderSlideMain = () => import('../../components/templates/product/info/gallery/GallerySliderSlideMain.vue' /* webpackChunkName: "components/product-info-gallery-slider-slide-main" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallerySliderSlideThumbnail = () => import('../../components/templates/product/info/gallery/GallerySliderSlideThumbnail.vue' /* webpackChunkName: "components/product-info-gallery-slider-slide-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallerySliders = () => import('../../components/templates/product/info/gallery/GallerySliders.vue' /* webpackChunkName: "components/product-info-gallery-sliders" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallerySlidersPopup = () => import('../../components/templates/product/info/gallery/GallerySlidersPopup.vue' /* webpackChunkName: "components/product-info-gallery-sliders-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGallerySlidersWrap = () => import('../../components/templates/product/info/gallery/GallerySlidersWrap.vue' /* webpackChunkName: "components/product-info-gallery-sliders-wrap" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoGalleryViewInRoom = () => import('../../components/templates/product/info/gallery/GalleryViewInRoom.vue' /* webpackChunkName: "components/product-info-gallery-view-in-room" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoMoreFromCollectionItem = () => import('../../components/templates/product/info/moreFromCollection/Item.vue' /* webpackChunkName: "components/product-info-more-from-collection-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressDetails = () => import('../../components/templates/product/info/recommendedMattress/Details.vue' /* webpackChunkName: "components/product-info-recommended-mattress-details" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressGallery = () => import('../../components/templates/product/info/recommendedMattress/Gallery.vue' /* webpackChunkName: "components/product-info-recommended-mattress-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressItem = () => import('../../components/templates/product/info/recommendedMattress/Item.vue' /* webpackChunkName: "components/product-info-recommended-mattress-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressOption = () => import('../../components/templates/product/info/recommendedMattress/Option.vue' /* webpackChunkName: "components/product-info-recommended-mattress-option" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressPopup = () => import('../../components/templates/product/info/recommendedMattress/Popup.vue' /* webpackChunkName: "components/product-info-recommended-mattress-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressPopupImgSlider = () => import('../../components/templates/product/info/recommendedMattress/PopupImgSlider.vue' /* webpackChunkName: "components/product-info-recommended-mattress-popup-img-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsCarousel = () => import('../../components/templates/product/info/reviews/Carousel.vue' /* webpackChunkName: "components/product-info-reviews-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsEmpty = () => import('../../components/templates/product/info/reviews/Empty.vue' /* webpackChunkName: "components/product-info-reviews-empty" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsFull = () => import('../../components/templates/product/info/reviews/Full.vue' /* webpackChunkName: "components/product-info-reviews-full" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItem = () => import('../../components/templates/product/info/reviews/Item.vue' /* webpackChunkName: "components/product-info-reviews-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItems = () => import('../../components/templates/product/info/reviews/Items.vue' /* webpackChunkName: "components/product-info-reviews-items" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsLoading = () => import('../../components/templates/product/info/reviews/Loading.vue' /* webpackChunkName: "components/product-info-reviews-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsStars = () => import('../../components/templates/product/info/reviews/Stars.vue' /* webpackChunkName: "components/product-info-reviews-stars" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsVote = () => import('../../components/templates/product/info/reviews/Vote.vue' /* webpackChunkName: "components/product-info-reviews-vote" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonAdditionalBottom = () => import('../../components/templates/product/skeleton/Additional/Bottom.vue' /* webpackChunkName: "components/product-skeleton-additional-bottom" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonAdditionalCenter = () => import('../../components/templates/product/skeleton/Additional/Center.vue' /* webpackChunkName: "components/product-skeleton-additional-center" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeletonAdditionalTop = () => import('../../components/templates/product/skeleton/Additional/Top.vue' /* webpackChunkName: "components/product-skeleton-additional-top" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAddressDeletePopup = () => import('../../components/templates/onepage/steps/address/DeletePopup.vue' /* webpackChunkName: "components/onepage-steps-address-delete-popup" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAddressForm = () => import('../../components/templates/onepage/steps/address/Form.vue' /* webpackChunkName: "components/onepage-steps-address-form" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAddressRegisteredEmailError = () => import('../../components/templates/onepage/steps/address/RegisteredEmailError.vue' /* webpackChunkName: "components/onepage-steps-address-registered-email-error" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsAddressVerificationPopup = () => import('../../components/templates/onepage/steps/address/VerificationPopup.vue' /* webpackChunkName: "components/onepage-steps-address-verification-popup" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipments = () => import('../../components/templates/onepage/steps/delivery/Shipments.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentActiveCardAddress = () => import('../../components/templates/onepage/steps/payment/ActiveCardAddress.vue' /* webpackChunkName: "components/onepage-steps-payment-active-card-address" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentBraintreeCard = () => import('../../components/templates/onepage/steps/payment/BraintreeCard.vue' /* webpackChunkName: "components/onepage-steps-payment-braintree-card" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentSelectedAddress = () => import('../../components/templates/onepage/steps/payment/SelectedAddress.vue' /* webpackChunkName: "components/onepage-steps-payment-selected-address" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentTypeCards = () => import('../../components/templates/onepage/steps/payment/TypeCards.vue' /* webpackChunkName: "components/onepage-steps-payment-type-cards" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentTypeInfo = () => import('../../components/templates/onepage/steps/payment/TypeInfo.vue' /* webpackChunkName: "components/onepage-steps-payment-type-info" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsPaymentTypeLogo = () => import('../../components/templates/onepage/steps/payment/TypeLogo.vue' /* webpackChunkName: "components/onepage-steps-payment-type-logo" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessPopupReviewsIo = () => import('../../components/templates/onepage/success/popup/ReviewsIo.vue' /* webpackChunkName: "components/onepage-success-popup-reviews-io" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessShipmentsItem = () => import('../../components/templates/onepage/success/shipments/Item.vue' /* webpackChunkName: "components/onepage-success-shipments-item" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessShipmentsItemDummyProducts = () => import('../../components/templates/onepage/success/shipments/ItemDummyProducts.vue' /* webpackChunkName: "components/onepage-success-shipments-item-dummy-products" */).then(c => wrapFunctional(c.default || c))
export const OnepageSuccessShipmentsItemInfo = () => import('../../components/templates/onepage/success/shipments/ItemInfo.vue' /* webpackChunkName: "components/onepage-success-shipments-item-info" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryShipmentsItem = () => import('../../components/templates/onepage/summary/shipments/Item.vue' /* webpackChunkName: "components/onepage-summary-shipments-item" */).then(c => wrapFunctional(c.default || c))
export const OnepageSummaryShipmentsMethod = () => import('../../components/templates/onepage/summary/shipments/Method.vue' /* webpackChunkName: "components/onepage-summary-shipments-method" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonTabSectionMainContent = () => import('../../components/templates/reviews/skeleton/TabSection/MainContent.vue' /* webpackChunkName: "components/reviews-skeleton-tab-section-main-content" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSkeletonTabSectionTopContent = () => import('../../components/templates/reviews/skeleton/TabSection/TopContent.vue' /* webpackChunkName: "components/reviews-skeleton-tab-section-top-content" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewMapSectionDirectionCallDetailSection = () => import('../../components/templates/reviews/view/MapSection/DirectionCallDetailSection.vue' /* webpackChunkName: "components/reviews-view-map-section-direction-call-detail-section" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewMapSectionGoogleMap = () => import('../../components/templates/reviews/view/MapSection/GoogleMap.vue' /* webpackChunkName: "components/reviews-view-map-section-google-map" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewMapSectionSiteLinks = () => import('../../components/templates/reviews/view/MapSection/SiteLinks.vue' /* webpackChunkName: "components/reviews-view-map-section-site-links" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewMapSectionSocialLinks = () => import('../../components/templates/reviews/view/MapSection/SocialLinks.vue' /* webpackChunkName: "components/reviews-view-map-section-social-links" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewSiteRatingsListItem = () => import('../../components/templates/reviews/view/SiteRatingsList/item.vue' /* webpackChunkName: "components/reviews-view-site-ratings-list-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionMainContent = () => import('../../components/templates/reviews/view/TabSection/MainContent.vue' /* webpackChunkName: "components/reviews-view-tab-section-main-content" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionTopContent = () => import('../../components/templates/reviews/view/TabSection/TopContent.vue' /* webpackChunkName: "components/reviews-view-tab-section-top-content" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewMapSectionDirectionCallDetailSection = () => import('../../components/templates/testimonials/view/MapSection/DirectionCallDetailSection.vue' /* webpackChunkName: "components/testimonials-view-map-section-direction-call-detail-section" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewMapSectionGoogleMap = () => import('../../components/templates/testimonials/view/MapSection/GoogleMap.vue' /* webpackChunkName: "components/testimonials-view-map-section-google-map" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewMapSectionSiteLinks = () => import('../../components/templates/testimonials/view/MapSection/SiteLinks.vue' /* webpackChunkName: "components/testimonials-view-map-section-site-links" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewMapSectionSocialLinks = () => import('../../components/templates/testimonials/view/MapSection/SocialLinks.vue' /* webpackChunkName: "components/testimonials-view-map-section-social-links" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewTabSectionMainContent = () => import('../../components/templates/testimonials/view/TabSection/MainContent.vue' /* webpackChunkName: "components/testimonials-view-tab-section-main-content" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewWriteReviewStarRating = () => import('../../components/templates/testimonials/view/WriteReview/StarRating.vue' /* webpackChunkName: "components/testimonials-view-write-review-star-rating" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorativeLeftSidebarShopByCategory = () => import('../../components/templates/category/view/decorative/LeftSidebar/ShopByCategory.vue' /* webpackChunkName: "components/category-view-decorative-left-sidebar-shop-by-category" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorativeLeftSidebarStaticLinks = () => import('../../components/templates/category/view/decorative/LeftSidebar/StaticLinks.vue' /* webpackChunkName: "components/category-view-decorative-left-sidebar-static-links" */).then(c => wrapFunctional(c.default || c))
export const CategoryViewDecorativeMainCategoryItem = () => import('../../components/templates/category/view/decorative/MainCategory/Item.vue' /* webpackChunkName: "components/category-view-decorative-main-category-item" */).then(c => wrapFunctional(c.default || c))
export const CouponCodeViewBottomItem = () => import('../../components/templates/couponCode/view/bottom/item/Item.vue' /* webpackChunkName: "components/coupon-code-view-bottom-item" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerCenterBannerDynamicItemBottom = () => import('../../components/templates/home/view/banner/CenterBannerDynamic/ItemBottom.vue' /* webpackChunkName: "components/home-view-banner-center-banner-dynamic-item-bottom" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerCenterBannerDynamicItemMain = () => import('../../components/templates/home/view/banner/CenterBannerDynamic/ItemMain.vue' /* webpackChunkName: "components/home-view-banner-center-banner-dynamic-item-main" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerCenterBannerStaticItem = () => import('../../components/templates/home/view/banner/CenterBannerStatic/Item.vue' /* webpackChunkName: "components/home-view-banner-center-banner-static-item" */).then(c => wrapFunctional(c.default || c))
export const HomeViewBannerSideBannerItem = () => import('../../components/templates/home/view/banner/SideBanner/Item.vue' /* webpackChunkName: "components/home-view-banner-side-banner-item" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelClearance = () => import('../../components/templates/listing/products/ListItem/label/Clearance.vue' /* webpackChunkName: "components/listing-products-list-item-label-clearance" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelDailyDeals = () => import('../../components/templates/listing/products/ListItem/label/DailyDeals.vue' /* webpackChunkName: "components/listing-products-list-item-label-daily-deals" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelDefault = () => import('../../components/templates/listing/products/ListItem/label/Default.vue' /* webpackChunkName: "components/listing-products-list-item-label-default" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelEarlyAccess = () => import('../../components/templates/listing/products/ListItem/label/EarlyAccess.vue' /* webpackChunkName: "components/listing-products-list-item-label-early-access" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelMattress = () => import('../../components/templates/listing/products/ListItem/label/Mattress.vue' /* webpackChunkName: "components/listing-products-list-item-label-mattress" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelOutOfStock = () => import('../../components/templates/listing/products/ListItem/label/OutOfStock.vue' /* webpackChunkName: "components/listing-products-list-item-label-out-of-stock" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelPrimaryDealTag = () => import('../../components/templates/listing/products/ListItem/label/PrimaryDealTag.vue' /* webpackChunkName: "components/listing-products-list-item-label-primary-deal-tag" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelSale = () => import('../../components/templates/listing/products/ListItem/label/Sale.vue' /* webpackChunkName: "components/listing-products-list-item-label-sale" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelSeasonalSale = () => import('../../components/templates/listing/products/ListItem/label/SeasonalSale.vue' /* webpackChunkName: "components/listing-products-list-item-label-seasonal-sale" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelSecondaryTag = () => import('../../components/templates/listing/products/ListItem/label/SecondaryTag.vue' /* webpackChunkName: "components/listing-products-list-item-label-secondary-tag" */).then(c => wrapFunctional(c.default || c))
export const ListingProductsListItemLabelTertiaryTag = () => import('../../components/templates/listing/products/ListItem/label/TertiaryTag.vue' /* webpackChunkName: "components/listing-products-list-item-label-tertiary-tag" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralCoupon = () => import('../../components/templates/product/info/details/general/Coupon.vue' /* webpackChunkName: "components/product-info-details-general-coupon" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralPrice = () => import('../../components/templates/product/info/details/general/Price.vue' /* webpackChunkName: "components/product-info-details-general-price" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralPriceMatch = () => import('../../components/templates/product/info/details/general/PriceMatch.vue' /* webpackChunkName: "components/product-info-details-general-price-match" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralPriceProtection = () => import('../../components/templates/product/info/details/general/PriceProtection.vue' /* webpackChunkName: "components/product-info-details-general-price-protection" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTags = () => import('../../components/templates/product/info/details/general/Tags.vue' /* webpackChunkName: "components/product-info-details-general-tags" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTitle = () => import('../../components/templates/product/info/details/general/Title.vue' /* webpackChunkName: "components/product-info-details-general-title" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalAddToCart = () => import('../../components/templates/product/info/details/additional/AddToCart.vue' /* webpackChunkName: "components/product-info-details-additional-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalAffirm = () => import('../../components/templates/product/info/details/additional/Affirm.vue' /* webpackChunkName: "components/product-info-details-additional-affirm" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalDeliveryContent = () => import('../../components/templates/product/info/details/additional/DeliveryContent.vue' /* webpackChunkName: "components/product-info-details-additional-delivery-content" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalGrouping = () => import('../../components/templates/product/info/details/additional/Grouping.vue' /* webpackChunkName: "components/product-info-details-additional-grouping" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalIncludeItems = () => import('../../components/templates/product/info/details/additional/IncludeItems.vue' /* webpackChunkName: "components/product-info-details-additional-include-items" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalIncludeToggle = () => import('../../components/templates/product/info/details/additional/IncludeToggle.vue' /* webpackChunkName: "components/product-info-details-additional-include-toggle" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalInformation = () => import('../../components/templates/product/info/details/additional/Information.vue' /* webpackChunkName: "components/product-info-details-additional-information" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalKatapult = () => import('../../components/templates/product/info/details/additional/Katapult.vue' /* webpackChunkName: "components/product-info-details-additional-katapult" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalOutOfStock = () => import('../../components/templates/product/info/details/additional/OutOfStock.vue' /* webpackChunkName: "components/product-info-details-additional-out-of-stock" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalProtection = () => import('../../components/templates/product/info/details/additional/Protection.vue' /* webpackChunkName: "components/product-info-details-additional-protection" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalQuestion = () => import('../../components/templates/product/info/details/additional/Question.vue' /* webpackChunkName: "components/product-info-details-additional-question" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalSetSelection = () => import('../../components/templates/product/info/details/additional/SetSelection.vue' /* webpackChunkName: "components/product-info-details-additional-set-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalShopMore = () => import('../../components/templates/product/info/details/additional/ShopMore.vue' /* webpackChunkName: "components/product-info-details-additional-shop-more" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoMoreFromCollectionItemGrouping = () => import('../../components/templates/product/info/moreFromCollection/item/Grouping.vue' /* webpackChunkName: "components/product-info-more-from-collection-item-grouping" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoRecommendedMattressDetailsHeading = () => import('../../components/templates/product/info/recommendedMattress/details/Heading.vue' /* webpackChunkName: "components/product-info-recommended-mattress-details-heading" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItemAction = () => import('../../components/templates/product/info/reviews/item/Action.vue' /* webpackChunkName: "components/product-info-reviews-item-action" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItemImages = () => import('../../components/templates/product/info/reviews/item/Images.vue' /* webpackChunkName: "components/product-info-reviews-item-images" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItemReview = () => import('../../components/templates/product/info/reviews/item/Review.vue' /* webpackChunkName: "components/product-info-reviews-item-review" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItemReviewer = () => import('../../components/templates/product/info/reviews/item/Reviewer.vue' /* webpackChunkName: "components/product-info-reviews-item-reviewer" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsBlock = () => import('../../components/templates/onepage/steps/delivery/shipments/Block.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-block" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsItem = () => import('../../components/templates/onepage/steps/delivery/shipments/Item.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-item" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsItemChilds = () => import('../../components/templates/onepage/steps/delivery/shipments/ItemChilds.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-item-childs" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsItemDummyProducts = () => import('../../components/templates/onepage/steps/delivery/shipments/ItemDummyProducts.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-item-dummy-products" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsItemInfo = () => import('../../components/templates/onepage/steps/delivery/shipments/ItemInfo.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-item-info" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsMethod = () => import('../../components/templates/onepage/steps/delivery/shipments/Method.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-method" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsPreview = () => import('../../components/templates/onepage/steps/delivery/shipments/Preview.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-preview" */).then(c => wrapFunctional(c.default || c))
export const OnepageStepsDeliveryShipmentsPreviewItem = () => import('../../components/templates/onepage/steps/delivery/shipments/PreviewItem.vue' /* webpackChunkName: "components/onepage-steps-delivery-shipments-preview-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewsList = () => import('../../components/templates/reviews/view/TabSection/MainContent/ReviewsList.vue' /* webpackChunkName: "components/reviews-list" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionTopContentStarItem = () => import('../../components/templates/reviews/view/TabSection/TopContent/StarItem.vue' /* webpackChunkName: "components/reviews-view-tab-section-top-content-star-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionTopContentStarRating = () => import('../../components/templates/reviews/view/TabSection/TopContent/StarRating.vue' /* webpackChunkName: "components/reviews-view-tab-section-top-content-star-rating" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionTopContentSuccessMessage = () => import('../../components/templates/reviews/view/TabSection/TopContent/SuccessMessage.vue' /* webpackChunkName: "components/reviews-view-tab-section-top-content-success-message" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionTopContentWriteReviewForm = () => import('../../components/templates/reviews/view/TabSection/TopContent/WriteReviewForm.vue' /* webpackChunkName: "components/reviews-view-tab-section-top-content-write-review-form" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsList = () => import('../../components/templates/testimonials/view/TabSection/MainContent/TestimonialsList.vue' /* webpackChunkName: "components/testimonials-list" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTagsBanner = () => import('../../components/templates/product/info/details/general/Tags/Banner.vue' /* webpackChunkName: "components/product-info-details-general-tags-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTagsPrimary = () => import('../../components/templates/product/info/details/general/Tags/Primary.vue' /* webpackChunkName: "components/product-info-details-general-tags-primary" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTagsSecondary = () => import('../../components/templates/product/info/details/general/Tags/Secondary.vue' /* webpackChunkName: "components/product-info-details-general-tags-secondary" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsGeneralTagsTertiary = () => import('../../components/templates/product/info/details/general/Tags/Tertiary.vue' /* webpackChunkName: "components/product-info-details-general-tags-tertiary" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalDeliveryContentHomeDelivery = () => import('../../components/templates/product/info/details/additional/DeliveryContent/HomeDelivery.vue' /* webpackChunkName: "components/product-info-details-additional-delivery-content-home-delivery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalDeliveryContentWhiteGloveDelivery = () => import('../../components/templates/product/info/details/additional/DeliveryContent/WhiteGloveDelivery.vue' /* webpackChunkName: "components/product-info-details-additional-delivery-content-white-glove-delivery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalDeliveryContentZipCode = () => import('../../components/templates/product/info/details/additional/DeliveryContent/ZipCode.vue' /* webpackChunkName: "components/product-info-details-additional-delivery-content-zip-code" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalGroupingItem = () => import('../../components/templates/product/info/details/additional/grouping/Item.vue' /* webpackChunkName: "components/product-info-details-additional-grouping-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalGroupingFoundation = () => import('../../components/templates/product/info/details/additional/grouping/foundation.vue' /* webpackChunkName: "components/product-info-details-additional-grouping-foundation" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalInformationDescription = () => import('../../components/templates/product/info/details/additional/information/Description.vue' /* webpackChunkName: "components/product-info-details-additional-information-description" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalInformationDimensions = () => import('../../components/templates/product/info/details/additional/information/Dimensions.vue' /* webpackChunkName: "components/product-info-details-additional-information-dimensions" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalInformationMattressDescription = () => import('../../components/templates/product/info/details/additional/information/MattressDescription.vue' /* webpackChunkName: "components/product-info-details-additional-information-mattress-description" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDetailsAdditionalInformationSpecifications = () => import('../../components/templates/product/info/details/additional/information/Specifications.vue' /* webpackChunkName: "components/product-info-details-additional-information-specifications" */).then(c => wrapFunctional(c.default || c))
export const ReviewsRating = () => import('../../components/templates/reviews/view/TabSection/MainContent/ReviewsList/ReviewsRating.vue' /* webpackChunkName: "components/reviews-rating" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewTabSectionMainContentReviewsListItem = () => import('../../components/templates/reviews/view/TabSection/MainContent/ReviewsList/item.vue' /* webpackChunkName: "components/reviews-view-tab-section-main-content-reviews-list-item" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsRating = () => import('../../components/templates/testimonials/view/TabSection/MainContent/TestimonialsList/TestimonialsRating.vue' /* webpackChunkName: "components/testimonials-rating" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsViewTabSectionMainContentTestimonialsListItem = () => import('../../components/templates/testimonials/view/TabSection/MainContent/TestimonialsList/item.vue' /* webpackChunkName: "components/testimonials-view-tab-section-main-content-testimonials-list-item" */).then(c => wrapFunctional(c.default || c))
export const AlertsList = () => import('../../components/common/AlertsList.vue' /* webpackChunkName: "components/alerts-list" */).then(c => wrapFunctional(c.default || c))
export const BaseAlert = () => import('../../components/common/BaseAlert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumbs = () => import('../../components/common/BaseBreadcrumbs.vue' /* webpackChunkName: "components/base-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumbsScroll = () => import('../../components/common/BaseBreadcrumbsScroll.vue' /* webpackChunkName: "components/base-breadcrumbs-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseDataTable = () => import('../../components/common/BaseDataTable.vue' /* webpackChunkName: "components/base-data-table" */).then(c => wrapFunctional(c.default || c))
export const BaseDialog = () => import('../../components/common/BaseDialog.vue' /* webpackChunkName: "components/base-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseExpansionPanel = () => import('../../components/common/BaseExpansionPanel.vue' /* webpackChunkName: "components/base-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseExpansionPanels = () => import('../../components/common/BaseExpansionPanels.vue' /* webpackChunkName: "components/base-expansion-panels" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/common/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../components/common/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseLoader = () => import('../../components/common/BaseLoader.vue' /* webpackChunkName: "components/base-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseProductsSlider = () => import('../../components/common/BaseProductsSlider.vue' /* webpackChunkName: "components/base-products-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseRecaptcha = () => import('../../components/common/BaseRecaptcha.vue' /* webpackChunkName: "components/base-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const BaseScrollHorizontal = () => import('../../components/common/BaseScrollHorizontal.vue' /* webpackChunkName: "components/base-scroll-horizontal" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/common/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseShowMore = () => import('../../components/common/BaseShowMore.vue' /* webpackChunkName: "components/base-show-more" */).then(c => wrapFunctional(c.default || c))
export const BaseStars = () => import('../../components/common/BaseStars.vue' /* webpackChunkName: "components/base-stars" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitch = () => import('../../components/common/BaseSwitch.vue' /* webpackChunkName: "components/base-switch" */).then(c => wrapFunctional(c.default || c))
export const LinkWithArrow = () => import('../../components/common/LinkWithArrow.vue' /* webpackChunkName: "components/link-with-arrow" */).then(c => wrapFunctional(c.default || c))
export const SelectQuantity = () => import('../../components/common/SelectQuantity.vue' /* webpackChunkName: "components/select-quantity" */).then(c => wrapFunctional(c.default || c))
export const StarRating = () => import('../../components/common/StarRating.vue' /* webpackChunkName: "components/star-rating" */).then(c => wrapFunctional(c.default || c))
export const FormCreditCard = () => import('../../components/common/form/CreditCard.vue' /* webpackChunkName: "components/form-credit-card" */).then(c => wrapFunctional(c.default || c))
export const FormErrors = () => import('../../components/common/form/Errors.vue' /* webpackChunkName: "components/form-errors" */).then(c => wrapFunctional(c.default || c))
export const FormRegionIdDropdown = () => import('../../components/common/form/RegionIdDropdown.vue' /* webpackChunkName: "components/form-region-id-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormInputPassword = () => import('../../components/common/form/inputPassword.vue' /* webpackChunkName: "components/form-input-password" */).then(c => wrapFunctional(c.default || c))
export const IconAccount32x32 = () => import('../../components/common/icon/Account-32x32.vue' /* webpackChunkName: "components/icon-account32x32" */).then(c => wrapFunctional(c.default || c))
export const IconAdd16x16 = () => import('../../components/common/icon/Add-16x16.vue' /* webpackChunkName: "components/icon-add16x16" */).then(c => wrapFunctional(c.default || c))
export const IconAddBoutique16x16 = () => import('../../components/common/icon/AddBoutique-16x16.vue' /* webpackChunkName: "components/icon-add-boutique16x16" */).then(c => wrapFunctional(c.default || c))
export const IconAdded16x16 = () => import('../../components/common/icon/Added-16x16.vue' /* webpackChunkName: "components/icon-added16x16" */).then(c => wrapFunctional(c.default || c))
export const IconAlertBlue49x49 = () => import('../../components/common/icon/AlertBlue-49x49.vue' /* webpackChunkName: "components/icon-alert-blue49x49" */).then(c => wrapFunctional(c.default || c))
export const IconAlertRed16x16 = () => import('../../components/common/icon/AlertRed-16x16.vue' /* webpackChunkName: "components/icon-alert-red16x16" */).then(c => wrapFunctional(c.default || c))
export const IconArrowNarrow10x8 = () => import('../../components/common/icon/ArrowNarrow-10x8.vue' /* webpackChunkName: "components/icon-arrow-narrow10x8" */).then(c => wrapFunctional(c.default || c))
export const IconArrowNarrow20x12 = () => import('../../components/common/icon/ArrowNarrow-20x12.vue' /* webpackChunkName: "components/icon-arrow-narrow20x12" */).then(c => wrapFunctional(c.default || c))
export const IconArrowNext13x21 = () => import('../../components/common/icon/ArrowNext-13x21.vue' /* webpackChunkName: "components/icon-arrow-next13x21" */).then(c => wrapFunctional(c.default || c))
export const IconArrowNext21x36 = () => import('../../components/common/icon/ArrowNext-21x36.vue' /* webpackChunkName: "components/icon-arrow-next21x36" */).then(c => wrapFunctional(c.default || c))
export const IconArrowNext9x14 = () => import('../../components/common/icon/ArrowNext-9x14.vue' /* webpackChunkName: "components/icon-arrow-next9x14" */).then(c => wrapFunctional(c.default || c))
export const IconArrowPrev21x36 = () => import('../../components/common/icon/ArrowPrev-21x36.vue' /* webpackChunkName: "components/icon-arrow-prev21x36" */).then(c => wrapFunctional(c.default || c))
export const IconBrand32x32 = () => import('../../components/common/icon/Brand-32x32.vue' /* webpackChunkName: "components/icon-brand32x32" */).then(c => wrapFunctional(c.default || c))
export const IconBrand48x48 = () => import('../../components/common/icon/Brand-48x48.vue' /* webpackChunkName: "components/icon-brand48x48" */).then(c => wrapFunctional(c.default || c))
export const IconCall24x24 = () => import('../../components/common/icon/Call-24x24.vue' /* webpackChunkName: "components/icon-call24x24" */).then(c => wrapFunctional(c.default || c))
export const IconCallUs48x48 = () => import('../../components/common/icon/CallUs-48x48.vue' /* webpackChunkName: "components/icon-call-us48x48" */).then(c => wrapFunctional(c.default || c))
export const IconCart128x128 = () => import('../../components/common/icon/Cart-128x128.vue' /* webpackChunkName: "components/icon-cart128x128" */).then(c => wrapFunctional(c.default || c))
export const IconCart24x24 = () => import('../../components/common/icon/Cart-24x24.vue' /* webpackChunkName: "components/icon-cart24x24" */).then(c => wrapFunctional(c.default || c))
export const IconCart32x32 = () => import('../../components/common/icon/Cart-32x32.vue' /* webpackChunkName: "components/icon-cart32x32" */).then(c => wrapFunctional(c.default || c))
export const IconCart48x48 = () => import('../../components/common/icon/Cart-48x48.vue' /* webpackChunkName: "components/icon-cart48x48" */).then(c => wrapFunctional(c.default || c))
export const IconCheckmark24x24 = () => import('../../components/common/icon/Checkmark24x24.vue' /* webpackChunkName: "components/icon-checkmark24x24" */).then(c => wrapFunctional(c.default || c))
export const IconCheckmarkLightBlue32x32 = () => import('../../components/common/icon/CheckmarkLightBlue-32x32.vue' /* webpackChunkName: "components/icon-checkmark-light-blue32x32" */).then(c => wrapFunctional(c.default || c))
export const IconCheckmarkRound16x16 = () => import('../../components/common/icon/CheckmarkRound-16x16.vue' /* webpackChunkName: "components/icon-checkmark-round16x16" */).then(c => wrapFunctional(c.default || c))
export const IconChevronLeft8x14 = () => import('../../components/common/icon/ChevronLeft-8x14.vue' /* webpackChunkName: "components/icon-chevron-left8x14" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight8x14 = () => import('../../components/common/icon/ChevronRight-8x14.vue' /* webpackChunkName: "components/icon-chevron-right8x14" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUp16x16 = () => import('../../components/common/icon/ChevronUp-16x16.vue' /* webpackChunkName: "components/icon-chevron-up16x16" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUpArrow14x18 = () => import('../../components/common/icon/ChevronUpArrow-14x18.vue' /* webpackChunkName: "components/icon-chevron-up-arrow14x18" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUpArrow14x9 = () => import('../../components/common/icon/ChevronUpArrow-14x9.vue' /* webpackChunkName: "components/icon-chevron-up-arrow14x9" */).then(c => wrapFunctional(c.default || c))
export const IconClose14x14 = () => import('../../components/common/icon/Close-14x14.vue' /* webpackChunkName: "components/icon-close14x14" */).then(c => wrapFunctional(c.default || c))
export const IconClose16x16 = () => import('../../components/common/icon/Close-16x16.vue' /* webpackChunkName: "components/icon-close16x16" */).then(c => wrapFunctional(c.default || c))
export const IconClose17x17 = () => import('../../components/common/icon/Close-17x17.vue' /* webpackChunkName: "components/icon-close17x17" */).then(c => wrapFunctional(c.default || c))
export const IconClose20x20 = () => import('../../components/common/icon/Close-20x20.vue' /* webpackChunkName: "components/icon-close20x20" */).then(c => wrapFunctional(c.default || c))
export const IconClose24x24 = () => import('../../components/common/icon/Close-24x24.vue' /* webpackChunkName: "components/icon-close24x24" */).then(c => wrapFunctional(c.default || c))
export const IconComfortLevel32x32 = () => import('../../components/common/icon/ComfortLevel-32x32.vue' /* webpackChunkName: "components/icon-comfort-level32x32" */).then(c => wrapFunctional(c.default || c))
export const IconCoupon48x48 = () => import('../../components/common/icon/Coupon-48x48.vue' /* webpackChunkName: "components/icon-coupon48x48" */).then(c => wrapFunctional(c.default || c))
export const IconCustomers24x24 = () => import('../../components/common/icon/Customers-24x24.vue' /* webpackChunkName: "components/icon-customers24x24" */).then(c => wrapFunctional(c.default || c))
export const IconDeletePaymentMethod128x103 = () => import('../../components/common/icon/DeletePaymentMethod-128x103.vue' /* webpackChunkName: "components/icon-delete-payment-method128x103" */).then(c => wrapFunctional(c.default || c))
export const IconEdit18x16 = () => import('../../components/common/icon/Edit-18x16.vue' /* webpackChunkName: "components/icon-edit18x16" */).then(c => wrapFunctional(c.default || c))
export const IconFilter16x16 = () => import('../../components/common/icon/Filter-16x16.vue' /* webpackChunkName: "components/icon-filter16x16" */).then(c => wrapFunctional(c.default || c))
export const IconForwardArrow16x16 = () => import('../../components/common/icon/ForwardArrow-16x16.vue' /* webpackChunkName: "components/icon-forward-arrow16x16" */).then(c => wrapFunctional(c.default || c))
export const IconForwardArrow17x12 = () => import('../../components/common/icon/ForwardArrow-17x12.vue' /* webpackChunkName: "components/icon-forward-arrow17x12" */).then(c => wrapFunctional(c.default || c))
export const IconForwardArrow24x24 = () => import('../../components/common/icon/ForwardArrow-24x24.vue' /* webpackChunkName: "components/icon-forward-arrow24x24" */).then(c => wrapFunctional(c.default || c))
export const IconFurnitureSale295x93 = () => import('../../components/common/icon/FurnitureSale-295x93.vue' /* webpackChunkName: "components/icon-furniture-sale295x93" */).then(c => wrapFunctional(c.default || c))
export const IconHamburger24x24 = () => import('../../components/common/icon/Hamburger-24x24.vue' /* webpackChunkName: "components/icon-hamburger24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilter16x16 = () => import('../../components/common/icon/HideFilter-16x16.vue' /* webpackChunkName: "components/icon-hide-filter16x16" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV2Black16x16 = () => import('../../components/common/icon/HideFilterV2Black-16x16.vue' /* webpackChunkName: "components/icon-hide-filter-v2-black16x16" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV2White16x16 = () => import('../../components/common/icon/HideFilterV2White-16x16.vue' /* webpackChunkName: "components/icon-hide-filter-v2-white16x16" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV3Black16x16 = () => import('../../components/common/icon/HideFilterV3Black-16x16.vue' /* webpackChunkName: "components/icon-hide-filter-v3-black16x16" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV3White16x16 = () => import('../../components/common/icon/HideFilterV3White-16x16.vue' /* webpackChunkName: "components/icon-hide-filter-v3-white16x16" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV6Black19x14 = () => import('../../components/common/icon/HideFilterV6Black-19x14.vue' /* webpackChunkName: "components/icon-hide-filter-v6-black19x14" */).then(c => wrapFunctional(c.default || c))
export const IconHideFilterV6White19x14 = () => import('../../components/common/icon/HideFilterV6White-19x14.vue' /* webpackChunkName: "components/icon-hide-filter-v6-white19x14" */).then(c => wrapFunctional(c.default || c))
export const IconAckReceived200x160 = () => import('../../components/common/icon/IconAckReceived-200x160.vue' /* webpackChunkName: "components/icon-ack-received200x160" */).then(c => wrapFunctional(c.default || c))
export const IconCheckmarkRound24x24 = () => import('../../components/common/icon/IconCheckmarkRound-24x24.vue' /* webpackChunkName: "components/icon-checkmark-round24x24" */).then(c => wrapFunctional(c.default || c))
export const IconFurnitureSale2348x142 = () => import('../../components/common/icon/IconFurnitureSale2-348x142.vue' /* webpackChunkName: "components/icon-furniture-sale2348x142" */).then(c => wrapFunctional(c.default || c))
export const IconWishList24x24 = () => import('../../components/common/icon/IconWishList-24x24.vue' /* webpackChunkName: "components/icon-wish-list24x24" */).then(c => wrapFunctional(c.default || c))
export const IconInTransit200x160 = () => import('../../components/common/icon/InTransit-200x160.vue' /* webpackChunkName: "components/icon-in-transit200x160" */).then(c => wrapFunctional(c.default || c))
export const IconLock24x24 = () => import('../../components/common/icon/Lock-24x24.vue' /* webpackChunkName: "components/icon-lock24x24" */).then(c => wrapFunctional(c.default || c))
export const IconLogoWhiteMobile96x33 = () => import('../../components/common/icon/LogoWhiteMobile-96x33.vue' /* webpackChunkName: "components/icon-logo-white-mobile96x33" */).then(c => wrapFunctional(c.default || c))
export const IconLowAlert32x32 = () => import('../../components/common/icon/LowAlert-32x32.vue' /* webpackChunkName: "components/icon-low-alert32x32" */).then(c => wrapFunctional(c.default || c))
export const IconLowAlertred32x32 = () => import('../../components/common/icon/LowAlertred-32x32.vue' /* webpackChunkName: "components/icon-low-alertred32x32" */).then(c => wrapFunctional(c.default || c))
export const IconMail24x24 = () => import('../../components/common/icon/Mail-24x24.vue' /* webpackChunkName: "components/icon-mail24x24" */).then(c => wrapFunctional(c.default || c))
export const IconMattress48x48 = () => import('../../components/common/icon/Mattress-48x48.vue' /* webpackChunkName: "components/icon-mattress48x48" */).then(c => wrapFunctional(c.default || c))
export const IconMattressThickness32x32 = () => import('../../components/common/icon/MattressThickness-32x32.vue' /* webpackChunkName: "components/icon-mattress-thickness32x32" */).then(c => wrapFunctional(c.default || c))
export const IconMattressType32x32 = () => import('../../components/common/icon/MattressType-32x32.vue' /* webpackChunkName: "components/icon-mattress-type32x32" */).then(c => wrapFunctional(c.default || c))
export const IconOrderProcessing200x160 = () => import('../../components/common/icon/OrderProcessing-200x160.vue' /* webpackChunkName: "components/icon-order-processing200x160" */).then(c => wrapFunctional(c.default || c))
export const IconPillowTop32x32 = () => import('../../components/common/icon/PillowTop-32x32.vue' /* webpackChunkName: "components/icon-pillow-top32x32" */).then(c => wrapFunctional(c.default || c))
export const IconPriceDropTag200x160 = () => import('../../components/common/icon/PriceDropTag-200x160.vue' /* webpackChunkName: "components/icon-price-drop-tag200x160" */).then(c => wrapFunctional(c.default || c))
export const IconPriceTag62x62 = () => import('../../components/common/icon/PriceTag-62x62.vue' /* webpackChunkName: "components/icon-price-tag62x62" */).then(c => wrapFunctional(c.default || c))
export const IconPrivacy48x48 = () => import('../../components/common/icon/Privacy-48x48.vue' /* webpackChunkName: "components/icon-privacy48x48" */).then(c => wrapFunctional(c.default || c))
export const IconPrivate24x24 = () => import('../../components/common/icon/Private-24x24.vue' /* webpackChunkName: "components/icon-private24x24" */).then(c => wrapFunctional(c.default || c))
export const IconProtection24x24 = () => import('../../components/common/icon/Protection-24x24.vue' /* webpackChunkName: "components/icon-protection24x24" */).then(c => wrapFunctional(c.default || c))
export const IconProtection32x32 = () => import('../../components/common/icon/Protection-32x32.vue' /* webpackChunkName: "components/icon-protection32x32" */).then(c => wrapFunctional(c.default || c))
export const IconProtection48x48 = () => import('../../components/common/icon/Protection-48x48.vue' /* webpackChunkName: "components/icon-protection48x48" */).then(c => wrapFunctional(c.default || c))
export const IconProtection96x96 = () => import('../../components/common/icon/Protection-96x96.vue' /* webpackChunkName: "components/icon-protection96x96" */).then(c => wrapFunctional(c.default || c))
export const IconProtectionFull32x32 = () => import('../../components/common/icon/ProtectionFull-32x32.vue' /* webpackChunkName: "components/icon-protection-full32x32" */).then(c => wrapFunctional(c.default || c))
export const IconPublic24x24 = () => import('../../components/common/icon/Public-24x24.vue' /* webpackChunkName: "components/icon-public24x24" */).then(c => wrapFunctional(c.default || c))
export const IconQuestions200x160 = () => import('../../components/common/icon/Questions-200x160.vue' /* webpackChunkName: "components/icon-questions200x160" */).then(c => wrapFunctional(c.default || c))
export const IconRemove16x16 = () => import('../../components/common/icon/Remove-16x16.vue' /* webpackChunkName: "components/icon-remove16x16" */).then(c => wrapFunctional(c.default || c))
export const IconReviewCall24x24 = () => import('../../components/common/icon/ReviewCall-24x24.vue' /* webpackChunkName: "components/icon-review-call24x24" */).then(c => wrapFunctional(c.default || c))
export const IconReviewClock24x24 = () => import('../../components/common/icon/ReviewClock-24x24.vue' /* webpackChunkName: "components/icon-review-clock24x24" */).then(c => wrapFunctional(c.default || c))
export const IconReviewFacebook32x32 = () => import('../../components/common/icon/ReviewFacebook-32x32.vue' /* webpackChunkName: "components/icon-review-facebook32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewInstagram32x32 = () => import('../../components/common/icon/ReviewInstagram-32x32.vue' /* webpackChunkName: "components/icon-review-instagram32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewLocation24x24 = () => import('../../components/common/icon/ReviewLocation-24x24.vue' /* webpackChunkName: "components/icon-review-location24x24" */).then(c => wrapFunctional(c.default || c))
export const IconReviewNormalStar32x32 = () => import('../../components/common/icon/ReviewNormalStar-32x32.vue' /* webpackChunkName: "components/icon-review-normal-star32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewSelectedStar32x32 = () => import('../../components/common/icon/ReviewSelectedStar-32x32.vue' /* webpackChunkName: "components/icon-review-selected-star32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewTag24x24 = () => import('../../components/common/icon/ReviewTag-24x24.vue' /* webpackChunkName: "components/icon-review-tag24x24" */).then(c => wrapFunctional(c.default || c))
export const IconReviewTwitterX32x32 = () => import('../../components/common/icon/ReviewTwitterX-32x32.vue' /* webpackChunkName: "components/icon-review-twitter-x32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewUnselectedStar32x32 = () => import('../../components/common/icon/ReviewUnselectedStar-32x32.vue' /* webpackChunkName: "components/icon-review-unselected-star32x32" */).then(c => wrapFunctional(c.default || c))
export const IconReviewUrl24x24 = () => import('../../components/common/icon/ReviewUrl-24x24.vue' /* webpackChunkName: "components/icon-review-url24x24" */).then(c => wrapFunctional(c.default || c))
export const IconReviewYoutube32x32 = () => import('../../components/common/icon/ReviewYoutube-32x32.vue' /* webpackChunkName: "components/icon-review-youtube32x32" */).then(c => wrapFunctional(c.default || c))
export const IconRobot25x25 = () => import('../../components/common/icon/Robot-25x25.vue' /* webpackChunkName: "components/icon-robot25x25" */).then(c => wrapFunctional(c.default || c))
export const IconSearch24x24 = () => import('../../components/common/icon/Search-24x24.vue' /* webpackChunkName: "components/icon-search24x24" */).then(c => wrapFunctional(c.default || c))
export const IconSearchFilter20x20 = () => import('../../components/common/icon/SearchFilter-20x20.vue' /* webpackChunkName: "components/icon-search-filter20x20" */).then(c => wrapFunctional(c.default || c))
export const IconSearchFilter24x24 = () => import('../../components/common/icon/SearchFilter-24x24.vue' /* webpackChunkName: "components/icon-search-filter24x24" */).then(c => wrapFunctional(c.default || c))
export const IconSecure48x48 = () => import('../../components/common/icon/Secure-48x48.vue' /* webpackChunkName: "components/icon-secure48x48" */).then(c => wrapFunctional(c.default || c))
export const IconSertaLogo116x91 = () => import('../../components/common/icon/SertaLogo-116x91.vue' /* webpackChunkName: "components/icon-serta-logo116x91" */).then(c => wrapFunctional(c.default || c))
export const IconShopSimilar24x24 = () => import('../../components/common/icon/ShopSimilar-24x24.vue' /* webpackChunkName: "components/icon-shop-similar24x24" */).then(c => wrapFunctional(c.default || c))
export const IconShopSimilarCamera24x24 = () => import('../../components/common/icon/ShopSimilarCamera-24x24.vue' /* webpackChunkName: "components/icon-shop-similar-camera24x24" */).then(c => wrapFunctional(c.default || c))
export const IconShopSimilarPdp32x32 = () => import('../../components/common/icon/ShopSimilarPdp-32x32.vue' /* webpackChunkName: "components/icon-shop-similar-pdp32x32" */).then(c => wrapFunctional(c.default || c))
export const IconShopSimilarPdpWhite32x32 = () => import('../../components/common/icon/ShopSimilarPdp-white-32x32.vue' /* webpackChunkName: "components/icon-shop-similar-pdp-white32x32" */).then(c => wrapFunctional(c.default || c))
export const IconShopSimilarPdpBoutique32x32 = () => import('../../components/common/icon/ShopSimilarPdpBoutique-32x32.vue' /* webpackChunkName: "components/icon-shop-similar-pdp-boutique32x32" */).then(c => wrapFunctional(c.default || c))
export const IconSkip24x25 = () => import('../../components/common/icon/Skip-24x25.vue' /* webpackChunkName: "components/icon-skip24x25" */).then(c => wrapFunctional(c.default || c))
export const IconStar16x16 = () => import('../../components/common/icon/Star-16x16.vue' /* webpackChunkName: "components/icon-star16x16" */).then(c => wrapFunctional(c.default || c))
export const IconStarFill32x32 = () => import('../../components/common/icon/StarFill-32x32.vue' /* webpackChunkName: "components/icon-star-fill32x32" */).then(c => wrapFunctional(c.default || c))
export const IconStarHalf32x32 = () => import('../../components/common/icon/StarHalf-32x32.vue' /* webpackChunkName: "components/icon-star-half32x32" */).then(c => wrapFunctional(c.default || c))
export const IconStarOutlined24x24 = () => import('../../components/common/icon/StarOutlined-24x24.vue' /* webpackChunkName: "components/icon-star-outlined24x24" */).then(c => wrapFunctional(c.default || c))
export const IconStarOutlinedGray16x15 = () => import('../../components/common/icon/StarOutlinedGray-16x15.vue' /* webpackChunkName: "components/icon-star-outlined-gray16x15" */).then(c => wrapFunctional(c.default || c))
export const IconSubscribe48x34 = () => import('../../components/common/icon/Subscribe-48x34.vue' /* webpackChunkName: "components/icon-subscribe48x34" */).then(c => wrapFunctional(c.default || c))
export const IconSubscribe57x50 = () => import('../../components/common/icon/Subscribe-57x50.vue' /* webpackChunkName: "components/icon-subscribe57x50" */).then(c => wrapFunctional(c.default || c))
export const IconTag48x48 = () => import('../../components/common/icon/Tag-48x48.vue' /* webpackChunkName: "components/icon-tag48x48" */).then(c => wrapFunctional(c.default || c))
export const IconTracking128x103 = () => import('../../components/common/icon/Tracking-128x103.vue' /* webpackChunkName: "components/icon-tracking128x103" */).then(c => wrapFunctional(c.default || c))
export const IconUnboxInHouse200x160 = () => import('../../components/common/icon/UnboxInHouse-200x160.vue' /* webpackChunkName: "components/icon-unbox-in-house200x160" */).then(c => wrapFunctional(c.default || c))
export const IconUndo16x16 = () => import('../../components/common/icon/Undo-16x16.vue' /* webpackChunkName: "components/icon-undo16x16" */).then(c => wrapFunctional(c.default || c))
export const IconView3DBtnThumb30x18 = () => import('../../components/common/icon/View3DBtnThumb-30x18.vue' /* webpackChunkName: "components/icon-view3-d-btn-thumb30x18" */).then(c => wrapFunctional(c.default || c))
export const IconViewInRoom18x18 = () => import('../../components/common/icon/ViewInRoom-18x18.vue' /* webpackChunkName: "components/icon-view-in-room18x18" */).then(c => wrapFunctional(c.default || c))
export const IconWishList32x32 = () => import('../../components/common/icon/WishList-32x32.vue' /* webpackChunkName: "components/icon-wish-list32x32" */).then(c => wrapFunctional(c.default || c))
export const IconWrench32x32 = () => import('../../components/common/icon/Wrench-32x32.vue' /* webpackChunkName: "components/icon-wrench32x32" */).then(c => wrapFunctional(c.default || c))
export const IconZoomPdp24x24 = () => import('../../components/common/icon/ZoomPdp-24x24.vue' /* webpackChunkName: "components/icon-zoom-pdp24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountChatText24x24 = () => import('../../components/common/icon/Account/ChatText-24x24.vue' /* webpackChunkName: "components/icon-account-chat-text24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountOrder24x24 = () => import('../../components/common/icon/Account/Order-24x24.vue' /* webpackChunkName: "components/icon-account-order24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountPublic24x24 = () => import('../../components/common/icon/Account/Public-24x24.vue' /* webpackChunkName: "components/icon-account-public24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountReview24x24 = () => import('../../components/common/icon/Account/Review-24x24.vue' /* webpackChunkName: "components/icon-account-review24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountUser24x24 = () => import('../../components/common/icon/Account/User-24x24.vue' /* webpackChunkName: "components/icon-account-user24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountWishlist24x24 = () => import('../../components/common/icon/Account/Wishlist-24x24.vue' /* webpackChunkName: "components/icon-account-wishlist24x24" */).then(c => wrapFunctional(c.default || c))
export const IconAccountWishlistBoutique24x24 = () => import('../../components/common/icon/Account/WishlistBoutique-24x24.vue' /* webpackChunkName: "components/icon-account-wishlist-boutique24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderAccount24x24 = () => import('../../components/common/icon/Header/Account-24x24.vue' /* webpackChunkName: "components/icon-header-account24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderBrandsMobile24x24 = () => import('../../components/common/icon/Header/BrandsMobile-24x24.vue' /* webpackChunkName: "components/icon-header-brands-mobile24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderClearance24x24 = () => import('../../components/common/icon/Header/Clearance-24x24.vue' /* webpackChunkName: "components/icon-header-clearance24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderClearanceBoutique24x24 = () => import('../../components/common/icon/Header/ClearanceBoutique-24x24.vue' /* webpackChunkName: "components/icon-header-clearance-boutique24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderClearanceMobile24x24 = () => import('../../components/common/icon/Header/ClearanceMobile-24x24.vue' /* webpackChunkName: "components/icon-header-clearance-mobile24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderCloseBase24x24 = () => import('../../components/common/icon/Header/CloseBase-24x24.vue' /* webpackChunkName: "components/icon-header-close-base24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderContact24x24 = () => import('../../components/common/icon/Header/Contact-24x24.vue' /* webpackChunkName: "components/icon-header-contact24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderHamburger24x24 = () => import('../../components/common/icon/Header/Hamburger-24x24.vue' /* webpackChunkName: "components/icon-header-hamburger24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderHelpCenter24x24 = () => import('../../components/common/icon/Header/HelpCenter-24x24.vue' /* webpackChunkName: "components/icon-header-help-center24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderPhone24x24 = () => import('../../components/common/icon/Header/Phone-24x24.vue' /* webpackChunkName: "components/icon-header-phone24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderPromo24x24 = () => import('../../components/common/icon/Header/Promo-24x24.vue' /* webpackChunkName: "components/icon-header-promo24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderPromoBoutique24x24 = () => import('../../components/common/icon/Header/PromoBoutique-24x24.vue' /* webpackChunkName: "components/icon-header-promo-boutique24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderRecently24x24 = () => import('../../components/common/icon/Header/Recently-24x24.vue' /* webpackChunkName: "components/icon-header-recently24x24" */).then(c => wrapFunctional(c.default || c))
export const IconHeaderShipping24x24 = () => import('../../components/common/icon/Header/Shipping-24x24.vue' /* webpackChunkName: "components/icon-header-shipping24x24" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowNext16x28 = () => import('../../components/common/icon/KeenSlider/ArrowNext-16x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-next16x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowNext21x36 = () => import('../../components/common/icon/KeenSlider/ArrowNext-21x36.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-next21x36" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowNext8x14 = () => import('../../components/common/icon/KeenSlider/ArrowNext-8x14.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-next8x14" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowNextHover28x28 = () => import('../../components/common/icon/KeenSlider/ArrowNextHover-28x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-next-hover28x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowNextShadow28x28 = () => import('../../components/common/icon/KeenSlider/ArrowNextShadow-28x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-next-shadow28x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowPrev16x28 = () => import('../../components/common/icon/KeenSlider/ArrowPrev-16x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-prev16x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowPrev21x36 = () => import('../../components/common/icon/KeenSlider/ArrowPrev-21x36.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-prev21x36" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowPrev8x14 = () => import('../../components/common/icon/KeenSlider/ArrowPrev-8x14.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-prev8x14" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowPrevHover28x28 = () => import('../../components/common/icon/KeenSlider/ArrowPrevHover-28x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-prev-hover28x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderArrowPrevShadow28x28 = () => import('../../components/common/icon/KeenSlider/ArrowPrevShadow-28x28.vue' /* webpackChunkName: "components/icon-keen-slider-arrow-prev-shadow28x28" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderPlay64x64 = () => import('../../components/common/icon/KeenSlider/Play-64x64.vue' /* webpackChunkName: "components/icon-keen-slider-play64x64" */).then(c => wrapFunctional(c.default || c))
export const IconKeenSliderZoom42x42 = () => import('../../components/common/icon/KeenSlider/Zoom-42x42.vue' /* webpackChunkName: "components/icon-keen-slider-zoom42x42" */).then(c => wrapFunctional(c.default || c))
export const IconSocialsFacebook32x32 = () => import('../../components/common/icon/Socials/Facebook-32x32.vue' /* webpackChunkName: "components/icon-socials-facebook32x32" */).then(c => wrapFunctional(c.default || c))
export const IconSocialsInstagram32x32 = () => import('../../components/common/icon/Socials/Instagram-32x32.vue' /* webpackChunkName: "components/icon-socials-instagram32x32" */).then(c => wrapFunctional(c.default || c))
export const IconSocialsX32x32 = () => import('../../components/common/icon/Socials/X-32x32.vue' /* webpackChunkName: "components/icon-socials-x32x32" */).then(c => wrapFunctional(c.default || c))
export const IconSocialsYoutube32x32 = () => import('../../components/common/icon/Socials/Youtube-32x32.vue' /* webpackChunkName: "components/icon-socials-youtube32x32" */).then(c => wrapFunctional(c.default || c))
export const IconVuetifyClose = () => import('../../components/common/icon/vuetify/Close.vue' /* webpackChunkName: "components/icon-vuetify-close" */).then(c => wrapFunctional(c.default || c))
export const IconVuetifyNext = () => import('../../components/common/icon/vuetify/Next.vue' /* webpackChunkName: "components/icon-vuetify-next" */).then(c => wrapFunctional(c.default || c))
export const IconVuetifyPrev = () => import('../../components/common/icon/vuetify/Prev.vue' /* webpackChunkName: "components/icon-vuetify-prev" */).then(c => wrapFunctional(c.default || c))
export const StaticBurgerMenu = () => import('../../components/google/StaticBurgerMenu.vue' /* webpackChunkName: "components/static-burger-menu" */).then(c => wrapFunctional(c.default || c))
export const StaticFooter = () => import('../../components/google/StaticFooter.vue' /* webpackChunkName: "components/static-footer" */).then(c => wrapFunctional(c.default || c))
export const StaticHeader = () => import('../../components/google/StaticHeader.vue' /* webpackChunkName: "components/static-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
